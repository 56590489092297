import {Component, Input, OnInit} from '@angular/core';
import {ChartModel} from "../../model/chart-model.model";
import {ChartValues} from "../../model/chart-values.model";

@Component({
  selector: 'app-clouds',
  templateUrl: './clouds.component.html',
  styleUrls: ['./clouds.component.scss']
})
export class CloudsComponent implements OnInit {

  //stacked chart 3 levels per cloud height
  // https://codepen.io/natenorberg/pen/WwqRar

  // high = [12, 12, 13, 10, 9, 14, 20, 45, 89, 100, 100, 97];
  // medium = [0, 0, 0, 0, 1, 5, 0, 0, 0, 0, 0, 0];
  // low = [0, 0, 12, 45, 66, 67, 50, 78, 12, 0, 0, 2];

  @Input()
  highClouds: number[];
  @Input()
  mediumClouds: number[];
  @Input()
  lowClouds: number[];

  @Input()
  timestamps: any[];

  chartModel: ChartModel

  constructor() { }

  ngOnInit(): void {
    console.log(this.lowClouds)

    let dates = [];
    let now = new Date();
    dates.push(now);
    for (let i = 1; i < 5; i++) {
      dates.push(new Date().setHours(now.getHours() + i));
    }

    let low: ChartValues = {order: 0, color: 'darkgrey', fill: 'origin', title: 'Laag', pointRadius: 0, values: [0, 0, 12, 56, 56]};
    let lowClear: ChartValues = {order: 1, color: '#FFFFFF00', fill: '-1', title: 'Laag open', pointRadius: 0, values: [100, 100, 88, 44, 44]}
    let medium: ChartValues = {order: 2, color: 'grey', fill: '-1', title: 'Midden', pointRadius: 0, values: [2, 4, 6, 2, 0]};
    let medClear: ChartValues = {order: 3, color: '#FFFFFF00', fill: '-1', title: 'Med open', pointRadius: 0, values: [98, 96, 94, 98, 100]}
    let high: ChartValues = {order: 4, color: 'lightgrey', fill: '-1', title: 'Hoog', pointRadius: 0, values: [34, 12, 12, 30, 13]};
    let highClear: ChartValues = {order: 5, color: '#FFFFFF00', fill: '-1', title: 'High open', pointRadius: 0, values: [66, 88, 88, 70, 86]}

    this.chartModel =
      {title: 'Wolken', labels: dates, dateFormat: 'dd-MM-yyyy HH:mm:ss', options: {yAxis: {
            max: 300
          }},
        timeUnit: 'hour', unit: '%', data: [low, lowClear, medium, medClear, high, highClear]}

  }

  loadingEvent($event) {
    console.log('Loading event');
  }

  toggleHighlightElement(index: number, highlight: boolean) {
    let low = document.getElementById('low-' + index);
    let medium = document.getElementById('medium-' + index);
    let high = document.getElementById('high-' + index);
    let coverageHigh = document.getElementById('coverage-high-' + index);
    let coverageMedium = document.getElementById('coverage-medium-' + index);
    let coverageLow = document.getElementById('coverage-low-' + index);

    let date = document.getElementById('date-' + index);

    if (highlight) {
      low.classList.add('highlight');
      medium.classList.add('highlight');
      high.classList.add('highlight');
      date.style.display = 'block';
      coverageHigh.style.display = 'block';
      coverageMedium.style.display = 'block';
      coverageLow.style.display = 'block';
    } else {
      low.classList.remove('highlight');
      medium.classList.remove('highlight');
      high.classList.remove('highlight');
      date.style.display = 'none';
      coverageHigh.style.display = 'none';
      coverageMedium.style.display = 'none';
      coverageLow.style.display = 'none';    }
  }

  maxCoverage(index: number) {
    return Math.max(this.lowClouds[index], this.mediumClouds[index], this.highClouds[index]);
  }

}
