import { Component, OnInit } from '@angular/core';
import {TitleHeaderService} from "./title-header.service";
import {BehaviorSubject, Observable} from "rxjs";

@Component({
  selector: 'app-title-header',
  templateUrl: './title-header.component.html',
  styleUrls: ['./title-header.component.scss']
})
export class TitleHeaderComponent implements OnInit {

  title$: BehaviorSubject<string>;

  constructor(private titleHeaderService: TitleHeaderService) { }

  ngOnInit() {
    this.title$ = this.titleHeaderService.title$;
  }

}
