import {Component, Input, OnInit} from '@angular/core';
import {Forecast} from "../../model/forecast.model";

@Component({
  selector: 'app-forecast-temps',
  templateUrl: './forecast-temps.component.html',
  styleUrls: ['./forecast-temps.component.scss']
})
export class ForecastTempsComponent implements OnInit {

  @Input()
  forecast: Forecast
  tempColors = [
    '#9c2fae',
    '#663fb4',
    '#4055b2',
    '#587cf7',
    '#1daaf1',
    '#1ebdd0',
    '#159588',
    '#2d9a2d',
    '#8cc051', //15+
    '#fdc12f', //20+
    '#fd9728', //25+
    '#fb582f', //30+
    '#990000', //35+
    '#560808']; //40+

  constructor() { }

  ngOnInit(): void {
  }

  getTempColor(temp: number): string {
    if (temp == null) {
      return '#ffffff';
    }

    return this.tempColors[Math.floor((temp + 25) / 5)];
  }

  formatTemp(temp: number) {
    if (temp == null) {
      return '-';
    }

    return `${temp} ℃`;
  }
}
