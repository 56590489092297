<mat-card style="background-color: #fafafa; border: 1px solid #c8c8c8; font-family: Gravity, serif !important; margin-bottom: 5%;">
  <mat-card-title>
    Informatie over Meteodrenthe.nl
  </mat-card-title>
  <mat-card-content>
    <div style="padding: 2em; font-size: 16px;">
      <h3>Weerstation</h3>
      <p>Het weerstation van Meteodrenthe.nl staat in het buurtschap Lieving, een paar kilometer buiten Beilen. Daarmee is het centraal gelegen in de provincie Drenthe. De positie in het buitengebied voorkomt
      'urban heat island' effecten op temperatuurmetingen.</p>
      <p>Meteodrenthe.nl maakt voor de metingen vooral gebruik van de apparatuur van Davis Instruments.</p>
      <ul>
        <li>Temperatuur/luchtvochtigheid: Drie Sensirion SHT35 probes en een Davis 7346.070 thermo/hygro (SHT31) sensor op 1,5 meter hoogte.
          De primaire en secundaire SHT35 sensoren zijn geplaatst een Barani Meteo Shield Pro sensorhut, de Davis sensor in de standaard Davis Vantage Pro 2 sensorhut. Een derde SHT35 is op het moment gehuisd
         in een Davis 7714 sensorhut.</li>
        <li>Grondtempertuur: Een Davis 6470 sensorprobe meet temperatuur op 10 cm hoogte.</li>
        <li>Wind: Davis 6410 anemometer op ~ 5,2 meter. Een tweede anemometer op een hoogte ~ 1,5 meter</li>
        <li>Neerslag: Davis 6466M regenmeter</li>
        <li>Luchtdruk: Naast de metingen van de Davis WeatherLink leveren een BMP180 en BMP280 sensor luchtdrukmetingen</li>
        <li>Zonnestraling: Davis 6450 stralingssensor</li>
        <li>Luchtkwaliteit: Davis AirLink</li>
      </ul>
      <p>Hoewel de beschikbare dataset verder in het verleden terugreikt, zijn alle gegevens op deze website gebaseerd op data die niet verder teruggaat dan maart 2020. Data van voor die
      datum is gebaseerd op variabele locaties, meethoogtes en meetapparatuur. De data vanaf maart 2020 geeft de meest consistente weergave van de weersomstandigheden.</p>
      <h3>Voorspellingen</h3>
      <p>
      Voor de meerdaagse dag- en nachtvoorspelling maakt Meteodrenthe.nl gebruik van de API van Weather Underground (WU). De gegevens worden meerdere keren per dag ververst en zijn geldig
        voor de locatie van het weerstation. De ervaring is dat de voorspelling van WU vrij accuraat is, en niet onderdoet voor andere lokale voorspellingen, zoals die van RTV Drenthe.
      </p>
      <p>

      </p>
      <h3>Buienradar</h3>
      <p>
      De buienradar van Meteodrenthe.nl is een kaartweergave van OpenStreetMap, gecombineerd met radardata die door Rainviewer.com wordt verzameld en middels hun API beschikbaar wordt gesteld.
      </p>
      <h3>Techniek</h3>
      <p>
        De meeste metingen worden verzonden door een tweetal Davis sensor transmitters. Daarnaast zorgen diverse Wemos D1 Mini microcontrollers voor het verwerken van de gegevens van de SHT35 en BMPx sensoren.
        De data wordt eerst op het lokale netwerk verzameld in een Redis message queue op een Raspberry Pi. Daarvandaan wordt de data doorgestuurd naar de server van Meteodrenthe.nl, waar alle metingen in een database worden opgeslagen en relevante statstieken worden gegenereerd.
      </p>
      <p>
        De website van Meteodrenthe.nl is ontwikkeld met het Angular framework. De meeste backend functionaliteit is geschreven in Java.
      </p>
      <h3>Contact</h3>
      <p>
        Meteodrenthe.nl kan bereikt worden via info@meteodrenthe.nl.
      </p>
    </div>
  </mat-card-content>
</mat-card>
