<div [ngClass]="viewModeService.mobileViewMode ? 'astro-card-mobile mobile-card' : 'astro-card'" *ngIf="astroData">
  <div class="astro-container" [ngClass]="viewModeService.mobileViewMode ? 'astro-container-mobile' : ''">
    <div class="astro-element" title="Zonsopkomst">
<!--      <i class="wi wi-sunrise"></i>-->
      <img [src]="'../../assets/weather-icons/meteocons/fill/svg-static/sunrise.svg'"
           [height]="iconSize">
      <span class="data">
          {{astroData[0].sunRise | date : 'HH:mm':'':'nl-NL'}}
        </span>
    </div>
    <div class="astro-element" title="Daglengte">
<!--      <i class="wi wi-day-sunny"></i>-->
      <img [src]="'../../assets/weather-icons/meteocons/fill/svg-static/clear-day.svg'"
           [height]="iconSize">
      <span class="data">{{calculateDaylength(astroData[0].sunRise, astroData[0].sunSet)}}</span>
    </div>
    <div class="astro-element" title="Zonsondergang">
      <img [src]="'../../assets/weather-icons/meteocons/fill/svg-static/sunset.svg'"
           [height]="iconSize">
      <span class="data">
          {{astroData[0].sunSet | date : 'HH:mm':'':'nl-NL' || '-'}}
        </span>
    </div>
    <div class="astro-element" title="Maanopkomst">
      <img [src]="'../../assets/weather-icons/meteocons/fill/svg-static/moonrise.svg'"
           [height]="iconSize">
      <span class="data">
          {{(astroData[0].moonRise | date : 'HH:mm':'':'nl-NL') || '- -'}}
        </span>
    </div>
    <div class="astro-element" title="Maanfase">
      <img [src]="'../../assets/weather-icons/meteocons/fill/svg-static/' + getMoonPhaseIconFile(astroData[0].moonPhase)"
           [height]="iconSize">
      <span class="data">
          {{astroData[0].moonIllumination | number :'1.0-1'}}%
        </span>
    </div>
    <div class="astro-element" title="Maanondergang">
      <img [src]="'../../assets/weather-icons/meteocons/fill/svg-static/moonset.svg'"
           [height]="iconSize">      <span class="data">
          {{(astroData[0].moonSet | date : 'HH:mm':'':'nl-NL') || '- -'}}
        </span>
    </div>
  </div>
</div>
