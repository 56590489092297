import {Component, Input, OnInit} from '@angular/core';
import {TitleHeaderService} from "../title-header/title-header.service";
import {HttpService} from "../http/http.service";
import {YouTubeVideo} from "../model/youtube-video.model";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-weathercam',
  templateUrl: './weathercam.component.html',
  styleUrls: ['./weathercam.component.scss']
})
export class WeathercamComponent implements OnInit {

  private baseURI = 'https://api.meteodrenthe.nl/api/weather/weathercam/latest';
  imageURI = this.baseURI;

  @Input()
  subtitlePos: string = 'center';
  @Input()
  subtitleColor: string = 'grey';
  // videoSrc: string;
  // videoDesc: string;
  loaded = false;

  constructor(private titleHeaderService: TitleHeaderService,
              private httpService: HttpService,
              private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    this.titleHeaderService.setTitle("Weercam Lieving");
    setInterval(() => {
      this.imageURI = this.baseURI + '?ts=' + new Date().getTime();
    }, 12000);

    // const now: Date = new Date();
    // this.httpService.latestWeatherCamVideo().subscribe(v => {
    //   this.videoSrc = 'https://api.meteodrenthe.nl/api/video/' + v['fileName'];
    //   this.videoDesc = 'Weather cam ' + v['date'];
    // })
  }

  imageLoaded() {
    this.loaded = true;
  }
}
