<mat-card style="background-color: #ffffff; font-family: Gravity, serif !important; padding-top: 6px !important;" >
  <mat-card-content *ngIf="data" style="display: flex; flex-direction: column;">
    <div style="color: #3a3838; font-size: 36px; display: flex; justify-content: center;">
      <div style="display: flex; justify-content: center; width: 100%">
        <img [src]="'../../assets/weather-icons/meteocons/fill/svg-static/' + data.iconFile"
             [height]="56">
      </div>
    </div>
    <div style="text-align: center; margin-left: 3%; margin-right: 3%; border-bottom: 1px solid lightgrey; padding-bottom: 4%">
      <div style="font-size: 26px">
        <span style="padding-left: 25px">{{transform(data.primary?.value) || '-'}}</span>
        <span style="font-size: 20px"> {{data.primary?.unit}}</span>
        <i [class]="trendIcon(data.trend)"></i>
      </div>
      <div style="font-size: 16px">
        <span>{{data.primary?.name}}</span>
      </div>
    </div>
    <div style="margin-top: 3%; margin-bottom: 3%; min-height: 5em">
      <div *ngFor="let r of data.top" style="display: flex; margin-left: 5%; margin-right: 5%; justify-content: space-between; font-size: 14px; line-height: 1.5">
        <div>
          {{r?.name }}
        </div>
        <div>
          <span>{{transform(r?.value, data.dateFormat) || '-'}}</span>
          <span> {{r?.unit}}</span>
        </div>
      </div>
    </div>
    <div style="padding-top: 2%; margin-bottom: 2%; border-top: 1px solid lightgrey; min-height: 3.4em">
      <div *ngFor="let r of data.bottom" style="display: flex; margin-left: 5%; margin-right: 5%; justify-content: space-between; font-size: 14px; line-height: 1.5">
        <div>
          {{r?.name}}
        </div>
        <div [style.color]="colorByKey(r?.name)">
          <span>{{transform(r?.value, data.dateFormat) || '-'}}</span>
          <span> {{r?.unit}}</span>
        </div>
      </div>
    </div>

    <div style="font-size: 32px; text-align: center; vertical-align: middle; color: #777">
    </div>
  </mat-card-content>
</mat-card>
