import {Component, OnDestroy, OnInit} from '@angular/core';
import {SocketService} from "../socket/socket.service";
import {HttpService} from "../http/http.service";
import {SensorStatus} from "../model/sensor-status.model";
import {TitleHeaderService} from "../title-header/title-header.service";
import {ViewModeService} from "../view-mode/view-mode.service";

@Component({
  selector: 'app-sensors',
  templateUrl: './sensors.component.html',
  styleUrls: ['./sensors.component.scss']
})
export class SensorsComponent implements OnInit, OnDestroy {

  sensorStatuses: SensorStatus[];
  filter = '';
  isMobile = false;

  info = 'Op deze pagina vind je een weergave van alle actieve sensoren van het weerstation van Meteodrenthe.' +
    ' Updates worden real time getoond.'

  constructor(private socketService: SocketService,
              private httpService: HttpService,
              public viewModeService: ViewModeService,
              private titleHeaderService: TitleHeaderService) {
  }

  ngOnInit(): void {
    this.isMobile = this.viewModeService.isMobile;
    this.titleHeaderService.setTitle("Sensoren");
    this.httpService.getSensorStatuses().subscribe((ss) => {
      this.sensorStatuses = ss.sort((a, b) => this.sensorTypeOrder(a.sensorDetails.sensorType) - this.sensorTypeOrder(b.sensorDetails.sensorType));
      this.socketService.subscribe('/topic/sensors', (sensorStatus: SensorStatus) => {
        const counterPart = this.sensorStatuses.find((s) => s.sensorDetails.sensorId === sensorStatus.sensorDetails.sensorId);
        const updated = [];

        if (counterPart) {
          counterPart.readings.forEach((de) => {
            const newValue = sensorStatus.readings.find((e) => e.name === de.name).value;
            if (de.value !== newValue) {
              updated.push(de.name);
              de.value = newValue;
            }
          })
          counterPart.lastUpdate = sensorStatus.lastUpdate;
        } else {
          this.sensorStatuses.push(sensorStatus);
        }
        const timestampElement = document.getElementById('sensor-' + sensorStatus.sensorDetails.entityId + '-timestamp');
        if (timestampElement) {
          timestampElement.classList.add('sensor-updated');
          setTimeout(() => {
            timestampElement.classList.remove('sensor-updated');
          }, 1000);
        }
        updated.forEach((el) => {
          const valueElement = document.getElementById(el + '-' + counterPart.sensorDetails.entityId);
          if (valueElement) {
            valueElement.classList.add('sensor-updated');
            setTimeout(() => {
              valueElement.classList.remove('sensor-updated');
            }, 1000);
          }
        })
      })
    })
  }

  ngOnDestroy(): void {
    this.socketService.unsubscribe('/topic/sensors');
  }

  filtered(sensorStatus: SensorStatus): boolean {
    let doFilter = false;
    if (!this.filter || this.filter.length === 0) {
      doFilter = true;
    } else {
      this.filter.replace(new RegExp('[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\-]', 'g'), '').split(" ")
        .filter((str) => str.length > 0)
        .forEach((str) => {
          console.log(`checking ${str}`)
          const lowerCased = str.toLowerCase();
          const testString = ''.concat(sensorStatus.sensorDetails.sensorId)
            .concat(sensorStatus.sensorDetails.sensorType)
            .concat(sensorStatus.sensorDetails.description)
            .concat(sensorStatus.sensorDetails.shortName)
            .concat(sensorStatus.sensorDetails.remarks).toLowerCase()
            .replace(new RegExp('[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\-]', 'g'), '');

          if (testString.search(lowerCased) >= 0) {
            doFilter = true;
          }
        })
    }
    return doFilter;
  }

  toPrettySensorType(sensorType: string) {
    switch (sensorType) {
      case 'TEMP' :
        return 'Temperatuur';
      case 'TEMP_HYGRO' :
        return 'Temp/Hygro';
      case 'WIND' :
        return 'Wind';
      case 'PRESSURE' :
        return 'Luchtdruk';
      case 'SOLAR' :
        return 'Zonnestraling';
      case 'RAIN' :
        return 'Regen';
      case 'AIR' :
        return 'Luchtkwaliteit';
    }
  }

  // 'Temperatuur' | 'Luchtvochtigheid' | 'Windsnelheid' | 'Windrichting' | 'Luchtdruk' |
  // 'Zonnestraling' | 'Neerslag' | 'PM10' | 'PM2.5' | 'PM1'
  getReadingIcon(reading: string) {
    let icon;
    switch (reading) {
      case "Temperatuur": icon = 'wi wi-thermometer';
        break;
      case "Luchtvochtigheid": icon = 'wi wi-humidity';
        break;
      case "Windsnelheid": icon = 'wi wi-strong-wind';
        break;
      case "Windrichting": icon = 'wi wi-wind towards-0-deg';
        break;
      case "Luchtdruk": icon = 'wi wi-barometer';
        break;
      case "Zonnestraling": icon = 'wi wi-hot';
        break;
      case "Neerslag": icon = 'wi wi-raindrops';
        break;
      case "PM10":
      case "PM2.5":
      case "PM1": icon = 'wi wi-smog';
        break;
    }
    return icon;
  }

  sensorTypeOrder(sensorType: string) {
    return ['TEMP_HYGRO', 'TEMP', 'WIND', 'PRESSURE', 'SOLAR', 'RAIN', 'AIR'].indexOf(sensorType);
  }

}
