import {Component, OnInit} from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";
import {ViewMode} from "./enums/view-mode.enum";
import {ViewModeService} from "./view-mode/view-mode.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  viewMode: ViewMode;
  isMobile: boolean;

  constructor(private title: Title,
              private metaService: Meta,
              public viewModeService: ViewModeService) {
  }

  ngOnInit(): void {
    this.isMobile = this.viewModeService.isMobile;
    this.title.setTitle('Meteodrenthe.nl | Het weer in Drenthe');
    this.metaService.addTags([
      {name: 'keywords', content: 'drenthe, meteo, midden-drenthe, weer, lieving, beilen, temperatuur, wind, buienradar, pluim, weermodellen, ecmwf, gfs, harmonie, radar, regen, zonsopkomst, zonsondergang, weercam, weersverwachting'},
      {name: 'description', content: 'Het weer in Drenthe. Weersverwachting, pluim, weermodellen, buienradar, historische weerdata en weercam voor weerstation Lieving.'},
      {name: 'robots', content: 'index, follow'}
    ])
  }
}
