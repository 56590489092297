<app-month-year-selector [month]="month"
                         [year]="year"
                         (monthSelected)="setMonth($event)"
                         (yearSelected)="setYear($event)"></app-month-year-selector>
<div class="period-summary-container">
  <app-period-summary class="period-summary-tile" [rainSum]="monthlySum | async" [averages]="monthlyAverages" [extremes]="monthlyExtremes" [period]="monthName"></app-period-summary>
  <app-period-summary class="period-summary-tile" [rainSum]="yearlySum | async" [averages]="yearlyAverages | async" [extremes]="yearlyExtremes | async" [period]="year.toString()"></app-period-summary>
</div>
<app-statistics-chart [year]="year"
                      [month]="month"
                      [dailyExtremes]="dailyExtremes"
                      [dailyAverages]="dailyAverages"
                      [loading]="!loadedExtremes || !loadedAverages"></app-statistics-chart>
<app-extremes [dailyExtremes]="dailyExtremes"></app-extremes>
<app-averages [dailyAverages]="dailyAverages"></app-averages>
