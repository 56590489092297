import {Component, Input, OnInit} from '@angular/core';
import {Forecast} from "../../model/forecast.model";

@Component({
  selector: 'app-forecast-date',
  templateUrl: './forecast-date.component.html',
  styleUrls: ['./forecast-date.component.scss']
})
export class ForecastDateComponent implements OnInit {

  @Input()
  forecast: Forecast
  @Input()
  direction: 'horizontal' | 'vertical' = 'horizontal';

  constructor() { }

  ngOnInit(): void {
  }

}
