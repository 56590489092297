import { Component, OnInit } from '@angular/core';
import {HttpService} from "../http/http.service";

@Component({
  selector: 'app-visibility',
  templateUrl: './visibility.component.html',
  styleUrls: ['./visibility.component.scss']
})
export class VisibilityComponent implements OnInit {

  visibility: any;

  constructor(private httpService: HttpService) { }

  ngOnInit(): void {
    this.httpService.getLatestVisibility().subscribe(v => {
      this.visibility = v;
    })
  }

}
