<app-info-card [content]="info"></app-info-card>

<div class="flex-center">
    <mat-select class="p1em" [formControl]="daySelect" [style.width.px]="150" placeholder="Filter op dag">
      <mat-option [value]="undefined">Geen filter</mat-option>
      <mat-option *ngFor="let d of dates | async" [value]="d.date">{{d.label}}</mat-option>
    </mat-select>
</div>

<div class="spinner" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>
<div [style.padding-top.%]="viewModeService.mobileViewMode? 1 : 0"
     [style.display]="isLoading ? 'none' : ''">
  <app-chartjs [id]="'multiModel'"
               [height]="'400px'"
               [data]="multiModelTempChart | async"
               (loadingEvent)="multiModelLoadingEvent($event, 'temperature')"></app-chartjs>
</div>
<div style="margin-top: 20px;" [style.display]="isLoading ? 'none' : ''">
  <app-chartjs [id]="'multiModelWindSpeed'"
               [height]="'400px'"
               [data]="multiModelWindSpeedChart | async"
               (loadingEvent)="multiModelLoadingEvent($event, 'windSpeed')"
  ></app-chartjs>
</div>
<div style="margin-top: 20px;" [style.display]="isLoading ? 'none' : ''">
  <app-chartjs [id]="'multiModelWindDir'"
               [height]="'400px'"
               [data]="multiModelWindDirChart | async"
               (loadingEvent)="multiModelLoadingEvent($event, 'windDirection')"
  ></app-chartjs>
</div>
<div style="margin-top: 20px;" [style.display]="isLoading ? 'none' : ''">
  <app-chartjs [id]="'multiModelPrecipitation'"
               [height]="'400px'"
               [data]="multiModelPrecipitationChart | async"
               (loadingEvent)="multiModelLoadingEvent($event, 'precipitation')"
  ></app-chartjs>
</div>
<div style="margin-top: 20px;" [style.display]="isLoading ? 'none' : ''">
  <app-chartjs [id]="'multiModelCloudCover'"
               [height]="'400px'"
               [data]="multiModelCloudCoverChart | async"
               (loadingEvent)="multiModelLoadingEvent($event, 'cloudCover')"
  ></app-chartjs>
</div>
