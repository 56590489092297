import {Injectable, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TitleHeaderService implements OnInit {

  public title$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor() { }

  ngOnInit(): void {
  }

  setTitle(title: string, subtitle?: string) {
    this.title$.next({title: title, subtitle: subtitle});
  }
}
