<div class="spinner" *ngIf="!_dailyExtremes">
  <mat-spinner></mat-spinner>
</div>
<mat-card style="border: 1px solid #c8c8c8; margin-bottom: 3%" *ngIf="_dailyExtremes">



  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef> Datum </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.date | date : 'dd-MM':'':'nl-NL'}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="temp">
      <mat-header-cell *matHeaderCellDef> Temp (°C)</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span [style.color]="textColor(element.maxTemperature.value, 'maxTemperature')">{{element.maxTemperature.value | number :'1.1-1':'nl-NL'}}</span>
        <span style="padding: 0 0.3em 0 0.3em">|</span>
        <span [style.color]="textColor(element.minTemperature.value, 'minTemperature')"> {{element.minTemperature.value | number :'1.1-1':'nl-NL'}}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="hum">
      <mat-header-cell *matHeaderCellDef> Luchtv. (%) </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.maxHumidity.value }} | {{element.minHumidity.value }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="maxWind">
      <mat-header-cell *matHeaderCellDef> Max wind (km/u) </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.maxWindSpeed.value * 3.6 | number :'1.0-1':'nl-NL' }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="pressure">
      <mat-header-cell *matHeaderCellDef> Luchtdruk (hPa)</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.maxPressure.value | number :'1.0-1':'nl-NL'}} | {{element.minPressure.value | number :'1.0-1':'nl-NL' }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="maxSolar">
      <mat-header-cell *matHeaderCellDef> Max zon. (W/m²) </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.maxSolarRadiation.value | number :'1.0-1':'nl-NL'}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="rain">
      <mat-header-cell *matHeaderCellDef> Neerslag (mm) </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.totalRainfall.value  | number :'1.0-1'}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</mat-card>
