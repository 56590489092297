import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
  CategoryScale,
  Chart, Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement, SubTitle,
  TimeScale,
  Title,
  Tooltip
} from 'chart.js';
import {ChartModel} from "../../model/chart-model.model";
import 'chartjs-adapter-moment';
import {DatePipe, formatNumber} from "@angular/common";


@Component({
  selector: 'app-chartjs',
  templateUrl: './chartjs.component.html',
  styleUrls: ['./chartjs.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class ChartjsComponent implements OnInit, AfterViewInit {


  _data: ChartModel;
  @Input()
  id: string = 'id';
  title: string;

  @Output()
  loadingEvent = new EventEmitter<boolean>();

  @Input()
  height: string;
  @Input()
  lineThickness: number;
  @Input()
  displayLegend = true;
  @Input()
  stacked = false;

  lineChart: Chart;
  showChartCard = false;

  screenWidth: number;

  hiddenLegendItems: string[] = [];

  @ViewChild('canvas')
  canvas: ElementRef<HTMLCanvasElement>;

  constructor(private datePipe: DatePipe) {
    Chart.register(LineController, LineElement, PointElement, LinearScale,
      Title, CategoryScale, TimeScale, Legend, Tooltip, SubTitle, Filler);
  }

  @Input()
  set data(data: ChartModel) {
    this._data = data;
    if (this._data != null) {
      this.processData();
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    if (this._data != null) {
      this.processData();
    }
  }

  onResize(event) {
    this.screenWidth = event.target.innerWidth; // window width
  }


  processData() {
    if (!this.lineChart) {
      // new chart, trigger loading
      this.loadingEvent.emit(true);
    }

    let _this = this;
    let cd = this._data;
    this.title = cd.title;

    const datasets = cd.data.map(v => {
      return {
        data: v.values,
        borderDash: v.borderDash,
        order: v.order,
        backgroundColor: !!v.fill ? v.backgroundColor : 'transparent',
        borderColor: !!v.color ? v.color : 'black',
        fill: true,
        lineTension: 0,
        borderWidth: !!v.lineWidth ? v.lineWidth : 1.4,
        pointRadius: v.pointRadius,
        pointHoverRadius: 6,
        pointHitRadius: 16,
        showLine: v.showLine,
        label: v.title,
        hidden: _this.hiddenLegendItems.some((i) => i === v.title) ? true : v.hidden
      }
    });
    const labels = cd.labels;
    if (this.lineChart) {
      this.lineChart.destroy();
      this.showChartCard = false;
    }
    let defaultLegendClickHandler = Chart.defaults.plugins.legend.onClick;
    let legendClickFn = function (e, legendItem, legend) {
      const key = legendItem.text;
      if (!legendItem.hidden) {
        _this.hiddenLegendItems.push(key);
      } else {
        _this.hiddenLegendItems.splice(_this.hiddenLegendItems.indexOf(key), 1);
      }
      // @ts-ignore
      defaultLegendClickHandler(e, legendItem, legend);
    }
    let ctx = <HTMLCanvasElement>document.getElementById(this.id); // node

    let options: any = {
      responsive: true,
      maintainAspectRatio: false,
      animation: false,
      layout: {
        padding: {
          left: 2,
          bottom: 2
        }
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: (context) => {
              return formatNumber(context.raw, 'nl-NL') + cd.unit + ' | ' + context.dataset.label;
            }
          }
        },
        title: {
          display: true,
          text: cd.title,
          align: 'start',
          padding: {
            top: 5,
            bottom: 18
          },
          color: 'black',
          font: {
            family: 'Gravity, serif',
            size: 18,
            weight: 'normal'
          }
        },
        legend: {
          display: this.displayLegend,
          onClick: legendClickFn,
          labels: {
            boxWidth: 17,
            color: 'black',
            font: {
              family: 'Gravity, serif'
            }
          }
        }
      },
      scales: {
        x: {
          display: true,
          type: 'time',
          time: {
            tooltipFormat: this._data.dateFormat ? this._data.dateFormat : 'HH:mm',
            unit: this._data.timeUnit ? this._data.timeUnit : 'hour',
            displayFormats: {
              second: 'HH:mm',
              minute: 'HH:mm',
              hour: 'HH:mm',
              day: 'DD-MM'
            },
            stepSize: 1
          },
          ticks: {
            autoSkip: true,
            font: {
              size: 12,
              family: 'Gravity, serif'
            },
            callback: (value, index, ticks) => {
              let date = new Date(ticks[index].value);
              switch (this._data.timeUnit) {
                case 'day' : {
                  return this.datePipe.transform(date, 'EEE dd-MM', 'CET', 'nl-NL')
                }
                case 'hour' : {
                  return this.datePipe.transform(date, 'HH:mm', 'CET', 'nl-NL')
                }
                default: {
                  let format = 'HH:mm';
                  if (index === 0 || new Date(ticks[index - 1].value).getDay() !== date.getDay()) {
                    format = 'dd-MM-yy HH:mm'
                  }
                  return this.datePipe.transform(date, format, 'CET', 'nl-NL')
                }
              }
            },
            color: 'black',
            source: 'auto',
          }
        },
        y: {
          stacked: this.stacked,
          display: true,
          ticks: {
            font: {
              size: 12,
              family: 'Gravity, serif'
            },
            color: 'black',
            callback: function (value, index, values) {
              return (value % 1 !== 0 ? value.toFixed(1) : value) + cd.unit;
            }
          }
        },
      }
    };

    if (cd.options) {
      options = this.mergeDeep({}, options, cd.options)
    }

    if (cd.subtitle) {
      options = this.mergeDeep({}, options, {
        plugins: {
          subtitle: {
            display: true,
            align: 'start',
            text: cd.subtitle,
            padding: 0,
            position: 'bottom',
            color: 'grey',
            font: {
              family: 'Gravity, serif',
              size: 9,
              weight: 'normal'
            }
          }
        }
      })
    }

    const lineChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: datasets
      },
      options: options
    });

    if (this.lineChart) {
      this.lineChart.destroy();
    }
    this.showChartCard = true;
    this.lineChart = lineChart;
    this.loadingEvent.emit(false);


  }

  downloadImage() {
    let ctx = this.canvas.nativeElement.getContext('2d');

    ctx.globalCompositeOperation = 'destination-over'
    ctx.fillStyle = '#fff';  /// set white fill style
    ctx.fillRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);

    let a = document.createElement('a');
    a.href = this.lineChart.toBase64Image();
    a.download = `chart_meteodrenthe_${this.id}.png`;
    a.click();

    // reset to original value
    ctx.globalCompositeOperation = 'source-over'

  }


  //https://stackoverflow.com/questions/27936772/how-to-deep-merge-instead-of-shallow-merge
  isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
  }

  mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) Object.assign(target, {[key]: {}});
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, {[key]: source[key]});
        }
      }
    }

    return this.mergeDeep(target, ...sources);
  }
}
