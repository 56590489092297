import {Component, Input, OnInit} from '@angular/core';
import {Forecast} from "../../model/forecast.model";

@Component({
  selector: 'app-forecast-clouds',
  templateUrl: './forecast-clouds.component.html',
  styleUrls: ['./forecast-clouds.component.scss']
})
export class ForecastCloudsComponent implements OnInit {

  @Input()
  forecast: Forecast;

  constructor() { }

  ngOnInit(): void {
  }

  avgCoverage() {
    let avg = (this.forecast.dayTimeCloudCover + this.forecast.nightTimeCloudCover) / 2;
    return Math.round(avg);
  }

}
