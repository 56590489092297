<app-info-card [content]="info"></app-info-card>

<div class="spinner" *ngIf="!sensorStatuses">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="sensorStatuses" [style.padding-top.%]="viewModeService.mobileViewMode? 1 : 0">
  <mat-card style="border: 1px solid #c8c8c8; margin-bottom: 3%">
    <div>
      <mat-form-field style="padding: 5px 15px;">
        <input matInput placeholder="Filter..." [(ngModel)]="filter">
      </mat-form-field>
    </div>

    <mat-list>
      <mat-list-item *ngFor="let s of sensorStatuses" style="border-bottom: 2px solid #ecf0f5; height: inherit !important;"
                     [ngStyle]="{'display': !filtered(s) ? 'none' : ''}">
        <div style="width: 20%" [style.display]="isMobile ? 'none' : ''">{{toPrettySensorType(s.sensorDetails.sensorType)}}</div>
        <div [style.width.%]="isMobile ? 30 : 20">{{s.sensorDetails.description}}</div>
        <div style="width: 35%">
          <mat-list>
            <mat-list-item *ngFor="let r of s.readings">
              <div style="width: 25%">
                <i [ngClass]="getReadingIcon(r.name)"></i>
              </div>
              <div style="width: 75%; font-weight: bold">
                 <span [id]="r.name + '-' + s.sensorDetails.entityId">
                  {{r.value || '--'}} {{r.unit}}
                </span>
              </div>
            </mat-list-item>
          </mat-list>
        </div>
        <div [style.width.%]="isMobile ? 35 : 25">
          <span [id]="'sensor-' + s.sensorDetails.entityId + '-timestamp'">
            {{s.lastUpdate | date: 'dd-MM-yyy HH:mm:ss' : '' : 'nl-NL'}}
          </span>
        </div>
      </mat-list-item>
    </mat-list>
  </mat-card>
</div>
