<div class="spinner" *ngIf="isLoading">
  <mat-spinner></mat-spinner>
</div>
<div [style.padding-top.%]="viewModeService.mobileViewMode? 1 : 0"
     [style.display]="isLoading ? 'none' : ''">
  <app-chartjs [id]="'gfsEnsemble'"
               [height]="'400px'"
               [displayLegend]="false"
               [data]="ensembleGfsTemperature | async"
               (loadingEvent)="gfsEnsembleLoadingEvent($event, 'temperature')"></app-chartjs>
</div>
<div style="margin-top: 20px;"  [style.display]="isLoading ? 'none' : ''">
  <app-chartjs [id]="'gfsEnsemblePrecipitation'"
               [height]="'400px'"
               [displayLegend]="false"
               [data]="ensembleGfsPrecipitation | async"
               (loadingEvent)="gfsEnsembleLoadingEvent($event, 'precipitation')"></app-chartjs>
</div>
<div style="margin-top: 20px;"  [style.display]="isLoading ? 'none' : ''">
  <app-chartjs [id]="'gfsEnsembleWindSpeed'"
               [height]="'400px'"
               [displayLegend]="false"
               [data]="ensembleGfsWindSpeed | async"
               (loadingEvent)="gfsEnsembleLoadingEvent($event, 'windSpeed')"></app-chartjs>
</div>
<div style="margin-top: 20px;"  [style.display]="isLoading ? 'none' : ''">
  <app-chartjs [id]="'gfsEnsembleWindGusts'"
               [height]="'400px'"
               [displayLegend]="false"
               [data]="ensembleGfsWindGusts | async"
               (loadingEvent)="gfsEnsembleLoadingEvent($event, 'gusts')"></app-chartjs>
</div>
<div style="margin-top: 20px;"  [style.display]="isLoading ? 'none' : ''">
  <app-chartjs [id]="'gfsEnsembleWindDirection'"
               [height]="'400px'"
               [displayLegend]="false"
               [data]="ensembleGfsWindDirection | async"
               (loadingEvent)="gfsEnsembleLoadingEvent($event, 'windDirection')"></app-chartjs>
</div>
