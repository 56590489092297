<div style="display: flex; flex-direction: column">
  <div>
    <app-header></app-header>
  </div>
  <div id="main-container" class="main-container">
    <div [style.display]="viewModeService.mobileViewMode? 'none': 'flex'"
         style="justify-content: space-between; padding: 15px; margin-bottom: 5px;">
      <div>
        <app-title-header></app-title-header>
      </div>
      <div>
        <app-dateheader></app-dateheader>
      </div>
    </div>
    <router-outlet></router-outlet>
    <app-view-mode *ngIf="isMobile"></app-view-mode>
  </div>
  <div>
    <app-footer></app-footer>
  </div>
</div>
