<div>
  <app-tiles [stompClient]="stompClient"></app-tiles>
</div>
<div class="forecast-container">
  <div class="forecast">
    <div style="margin: 15px; font-family: Gravity, serif !important; display: flex;">
      <h1>Weersvoorspelling</h1>
    </div>
    <app-forecast [forecasts$]="forecasts$"></app-forecast>
  </div>
  <div class="radar-container radar" *ngIf="!viewModeSerice.isMobile">
    <div>
      <div style="margin: 15px 15px 24px;font-family: Gravity, serif !important;">
        <h1>Radar</h1>
      </div>
      <app-rainviewer [height]="'458px'" [minHeight]="'460px'"></app-rainviewer>
    </div>
  </div>
</div>
<div [ngClass]="viewModeSerice.mobileViewMode ? 'mobile-astro' : 'astro'">
    <div style="margin: 15px; font-family: Gravity, serif !important;">
      <h1>Zon en maan</h1>
    </div>
    <app-astro [astroData$]="astroData$"></app-astro>
</div>

