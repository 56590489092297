import {Component, Input, OnInit} from '@angular/core';
import {Forecast} from "../model/forecast.model";
import {HttpService} from "../http/http.service";
import {Observable} from "rxjs";
import {ViewModeService} from "../view-mode/view-mode.service";

@Component({
  selector: 'app-forecast',
  templateUrl: './forecast.component.html',
  styleUrls: ['./forecast.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class ForecastComponent implements OnInit {

  @Input()
  forecasts$: Observable<Forecast[]>;
  forecasts: Forecast[];
  tileNo = 14;
  screenWidth: number;
  showTitleColumn: boolean;



  constructor(public viewModeService: ViewModeService) { }

  ngOnInit() {
    this.screenWidth = window.innerWidth;
    this.showTitleColumn = this.screenWidth > 800;
    this.forecasts$.subscribe(forecasts => {
      forecasts = forecasts.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
      if (!forecasts[0].dayTimeTemp) {
        this.tileNo = 13;
      }
      this.forecasts = forecasts;
      if (!this.showTitleColumn) {
        this.tileNo = this.tileNo - 2;
      }
    });

  }

  onResize(event){
    this.screenWidth = event.target.innerWidth; // window width
    let showTitle = this.screenWidth > 800;
    if (!showTitle && this.showTitleColumn) {
      this.tileNo = this.tileNo -2;
    } else if (showTitle && !this.showTitleColumn) {
      this.tileNo = this.tileNo +2;
    }
    this.showTitleColumn = showTitle;
  }

  showFirstDayTime(): boolean {
    return this.tileNo === 14 || (this.tileNo === 12 && !this.showTitleColumn);
  }

  getDayTimeWeatherIcon(precipChance: number, cloudCover: number): string {
    if (cloudCover < 20) {
      return 'wi wi-day-sunny';
    } else if (cloudCover < 80) {
      if (precipChance < 50) {
        return 'wi wi-day-cloudy';
      } else {
        return 'wi wi-day-showers';
      }
    } else {
      if (precipChance < 50) {
        return 'wi wi-cloud';
      } else {
        return 'wi wi-showers';
      }
    }
  }

  getBeaufortIcon(beaufort: number) {
    return 'wi wi-wind-beaufort-' + beaufort;
  }

  getWindDirection(direction: string) {
    return 'wi wi-wind wi-towards-' + direction.toLowerCase();
  }

  getNightTimeWeatherIcon(precipChance: number, cloudCover: number, moonIllumination: number): string {
    if (cloudCover < 20) {
      if (moonIllumination < 25) {
        return 'wi wi-stars';
      } else if (moonIllumination < 95) {
        return 'wi wi-night-clear';
      } else {
        //optional full moon icon?
        return 'wi wi-night-clear';
      }
    } else if (cloudCover < 80) {
      if (precipChance < 50) {
        return 'wi wi-night-alt-cloudy';
      } else {
        return 'wi wi-night-alt-showers';
      }
    } else {
      if (precipChance < 50) {
        return 'wi wi-cloud';
      } else {
        return 'wi wi-showers';
      }
    }
  }

  toTemperatureString(temperature: number): string {
    if (temperature !== null) {
      return temperature + '°';
    } else {
      return null;
    }
  }

}
