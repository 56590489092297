import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-month-year-selector',
  templateUrl: './month-year-selector.component.html',
  styleUrls: ['./month-year-selector.component.scss']
})
export class MonthYearSelectorComponent implements OnInit {

  @Output()
  monthSelected = new EventEmitter<any>();
  @Output()
  yearSelected = new EventEmitter<number>();

  allMonths = ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"];
  months = ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"];
  years: number[];
  @Input()
  month: number;
  _year: number;

  constructor() { }

  @Input()
  set year(year: number) {
    console.log('set year ' + year)
    this._year = year;
  }

  ngOnInit() {
    this.years = [...Array((this._year - 2020) + 1).keys()].map(i => i + 2020);
    this.filterMonths(this._year);
  }

  setMonth(month: string) {
    this.month = this.allMonths.indexOf(month) + 1;
    this.monthSelected.emit({month: this.month, monthName: month});
  }

  setYear(year: number) {
    this._year = year;
    this.yearSelected.emit(year);
    this.filterMonths(year);
    this.setMonth(this.months[this.months.length - 1]);

  }

  filterMonths(year: number) {
    const now = new Date();
    if (now.getFullYear() === year) {
      this.months = this.allMonths.slice(0, now.getMonth() + 1);
    } else if (2020 === year) {
      this.months = this.allMonths.slice(2, this.allMonths.length); // starts at March
    } else {
      this.months = this.allMonths;
    }
    console.log(this.months);
  }

  monthAvailable(month: string): boolean {
    return this.months.includes(month);
  }


}
