<div class="chart-button-container">

  <div class="buttons">
    <button mat-button class="chart-button" (click)="setDataToShow('temp')">
      <img [src]="'../../assets/weather-icons/meteocons/fill/svg-static/thermometer-celsius.svg'"
           [height]="40">
<!--      <i class="wi wi-thermometer"></i>-->
    </button>
    <button mat-button class="chart-button" (click)="setDataToShow('wind')">
      <img [src]="'../../assets/weather-icons/meteocons/fill/svg-static/windsock.svg'"
           [height]="40">
<!--      <i class="wi wi-strong-wind"></i>-->
    </button>
    <button mat-button class="chart-button" (click)="setDataToShow('winddir')">
<!--      <i class="wi wi-wind towards-0-deg"></i>-->
      <img [src]="'../../assets/weather-icons/meteocons/fill/svg-static/compass.svg'"
           [height]="35">
    </button>
    <button mat-button class="chart-button" (click)="setDataToShow('pressure')">
<!--      <i class="wi wi-barometer"></i>-->
      <img [src]="'../../assets/weather-icons/meteocons/fill/svg-static/barometer.svg'"
           [height]="35">
    </button>
    <button mat-button class="chart-button" (click)="setDataToShow('radiation')">
      <img [src]="'../../assets/weather-icons/meteocons/fill/svg-static/sun-hot.svg'"
           [height]="38">
<!--      <i class="wi wi-hot"></i>-->
    </button>
    <button mat-button class="chart-button" (click)="setDataToShow('humidity')">
<!--      <i class="wi wi-humidity"></i>-->
      <img [src]="'../../assets/weather-icons/meteocons/fill/svg-static/humidity.svg'"
           [height]="40">
    </button>
    <button mat-button class="chart-button" (click)="setDataToShow('airquality')">
<!--      <i class="wi wi-smog"></i>-->
      <img [src]="'../../assets/weather-icons/meteocons/fill/svg-static/smoke-particles.svg'"
           [height]="40">
    </button>
    <button mat-button class="chart-button" (click)="setDataToShow('precipitation')">
<!--      <i class="wi wi-raindrop"></i>-->
      <img [src]="'../../assets/weather-icons/meteocons/fill/svg-static/rain.svg'"
           [height]="40">
    </button>
  </div>
  <mat-divider [vertical]="true" style="margin-left: 20px; margin-right: 20px;"></mat-divider>
  <mat-form-field style="width: 110px; font-size: 14px; font-family: inherit; height: 47px;">
    <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker1" [formControl]="startDate" style="font-weight: bold">
    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
  </mat-form-field>
  <div style="margin-bottom: -1px">
    <ngx-timepicker-field [format]="24" [(ngModel)]="startTime"></ngx-timepicker-field>
  </div>
  <span style="line-height: 3;
    margin: 0 9px 0 9px;">-</span>
  <mat-form-field style="width: 110px; font-size: 14px; font-family: inherit; height: 47px;">
    <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker2" [formControl]="endDate" style="font-weight: bold">
    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
    <mat-datepicker #picker2></mat-datepicker>
  </mat-form-field>
  <div style="margin-bottom: -1px">
    <ngx-timepicker-field [format]="24" [(ngModel)]="endTime"></ngx-timepicker-field>
  </div>
  <div style="margin-top: auto; margin-bottom: auto; padding-left: 30px">
    <span style="cursor: pointer; color: red">
      <i class="fas fa-history"
         title="Uren terug"
         [matMenuTriggerFor]="hourMenu"></i>
    </span>
    <mat-menu #hourMenu="matMenu">
      <button mat-menu-item title="1 uur" (click)="changeHours(1);">1 uur</button>
      <button mat-menu-item title="2 uur" (click)="changeHours(2);">2 uur</button>
      <button mat-menu-item title="3 uur" (click)="changeHours(3);">3 uur</button>
      <button mat-menu-item title="4 uur" (click)="changeHours(4);">4 uur (default)</button>
      <button mat-menu-item title="6 uur" (click)="changeHours(6);">6 uur</button>
      <button mat-menu-item title="12 uur" (click)="changeHours(12);">12 uur</button>
      <button mat-menu-item title="18 uur" (click)="changeHours(18);">18 uur</button>
      <button mat-menu-item title="24 uur"
              style="border-bottom: 1px solid grey;"
              (click)="changeHours(24);">24 uur (max)</button>
      <button *ngFor="let date of dateOptions"
              mat-menu-item
              title="{{date | date : 'EEEE' : '' : 'nl-NL'}}"
              (click)="setFullDate(date);">{{date | date : 'EEEE, d MMM' :'' : 'nl-NL'}}
      </button>

    </mat-menu>
  </div>
  <button mat-stroked-button style="margin-left: 15px; border: 0;" [disabled]="!canReload()" (click)="loadRawData(); processData();">
    <i class="fas fa-arrow-down"
       [ngClass]="queryingApi ? 'fa-spin' : ''"
       [style.color]="queryingApi ? '#7d00da' : canReload() ? 'black' : 'lightgrey'"
       style="cursor: pointer; font-size: 20px;"></i>
  </button>
  <mat-divider [vertical]="true" style="margin-left: 20px; margin-right: 20px;"></mat-divider>
  <div class="check" style="display: flex; margin-top: auto; margin-bottom: auto">
    <mat-checkbox [(ngModel)]="autoReload" (ngModelChange)="toggleLiveUpdate($event)">Live update</mat-checkbox>
    <mat-progress-spinner style="margin: 8px;" *ngIf="autoReload" [value]="reloadSpinnerValue" mode="determinate" diameter="20"></mat-progress-spinner>
  </div>
</div>
<div style="margin-bottom: 1%" *ngIf="shownData">
  <app-chartjs [data]="shownData | async" [id]="shownId" (loadingEvent)="loadingEvent($event)"></app-chartjs>
</div>
<div style="margin-bottom: 1%" *ngIf="shownId === 'precipitation'">
  <app-bar-chart [chartData]="precipitationData | async" [id]="shownId" (loadingEvent)="loadingEvent($event)"></app-bar-chart>
</div>

<div class="flex-center">
  <button mat-stroked-button *ngIf="!loading && !showFanRpm && shownId === 'temp'" (click)="showFanRpm = true;">Show fan RPM</button>
</div>

<div style="margin-bottom: 1%" *ngIf="shownId === 'temp' && showFanRpm">
  <app-chartjs [data]="fanRpmData | async" [id]="'fanSpeed'"></app-chartjs>
</div>

<app-loading *ngIf="shownData" [loading]="loading"></app-loading>

<!--<app-sensor-description-list *ngIf="shownSensors"-->
<!--                             [hide]="loading"-->
<!--                             [sensors]="shownSensors | async">-->
<!--</app-sensor-description-list>-->
