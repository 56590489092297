import {Component, Input, OnInit} from '@angular/core';
import {HttpService} from "../../http/http.service";
import {MatTableDataSource} from "@angular/material/table";
import {DailyAverages} from "../../model/daily-averages.model";
import {map, tap} from "rxjs/operators";
import {ChartModel} from "../../model/chart-model.model";
import {ChartValues} from "../../model/chart-values.model";
import {Observable, of} from "rxjs";

@Component({
  selector: 'app-averages',
  templateUrl: './averages.component.html',
  styleUrls: ['./averages.component.scss']
})
export class AveragesComponent implements OnInit {

  dataSource: MatTableDataSource<DailyAverages>;
  _dailyAverages: DailyAverages[];
  // tempChart: Observable<ChartModel>;
  displayedColumns = ['date', 'averageTemperature', 'averageHumidity', 'averageWindSpeed', 'dominantWindDirection',
  'averagePressure', 'averageSolarRadiation']

  constructor() { }

  ngOnInit() {}

  @Input()
  set dailyAverages(dailyAverages: DailyAverages[]) {
    this._dailyAverages = dailyAverages;
    this.dataSource = new MatTableDataSource<DailyAverages>(this._dailyAverages);
    // let cm = new ChartModel();
    // cm.title = 'Gemiddelde temperatuur';
    // cm.unit = '°C';
    // cm.labels = dav.map(v => v.date);
    // let cv = new ChartValues();
    // cv.color = 'red';
    // cv.title = 'Gem. temperatuur'
    // cv.pointRadius = 2;
    // cv.showLine = true;
    // cv.values = dav.map(v => v.averageTemperatureCelsius);
    // cm.data = [cv];
    // this.tempChart = of(cm);
  }

  textColor(value: number, key: string): string {
    const vals: number[] = this.dataSource.data.map(d => d[key].value)
    let color;
    if (value === Math.min(...vals)) {
      color = 'rgba(189,189,255,0.38)';
    } else if (value === Math.max(...vals)) {
      color = '#ffadad47';
    } else color = '';
    return color;
  }

}
