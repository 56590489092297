import {Component, Input, OnInit} from '@angular/core';
import {TileData} from "../../model/tile-data.model";

@Component({
  selector: 'app-mobile-tile',
  templateUrl: './mobile-tile.component.html',
  styleUrls: ['./mobile-tile.component.scss']
})
export class MobileTileComponent implements OnInit {

  @Input()
  tile: TileData;

  constructor() { }

  ngOnInit(): void {
  }

}
