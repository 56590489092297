<div style="width: 100%; display: flex; justify-content: center;">
  <div style="display: flex; min-width: 100px; ">
    <img [src]="'../../assets/weather-icons/meteocons/fill/svg-static/wind-beaufort-' + getMaxByKeys(forecast, ['dayTimeBeaufort', 'nightTimeBeaufort']) + '.svg'"
         [height]="direction === 'horizontal' ? 37 : 47">
    <div [style.font-size.px]="direction === 'horizontal' ? 15 : 18" style="display: flex; margin: auto 0; justify-content: center;">
      {{convertWindDirectionString(forecast.dayTimeWindDirection)}}
    </div>
  </div>

</div>
