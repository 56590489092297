import {TimestampedMeasurement} from "./timestamped-measurement.model";

export class SimpleExtremes {
  maxTemperature: TimestampedMeasurement<number>;
  minTemperature: TimestampedMeasurement<number>;
  maxHumidity: TimestampedMeasurement<number>;
  minHumidity: TimestampedMeasurement<number>;
  maxPressure: TimestampedMeasurement<number>;
  minPressure: TimestampedMeasurement<number>;
  maxSolarRadiation: TimestampedMeasurement<number>;
  maxWindSpeed: TimestampedMeasurement<number>;
}
