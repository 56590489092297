import {Component, Input, OnInit} from '@angular/core';
import {latLng, TileLayer, tileLayer} from "leaflet";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-rainviewer',
  templateUrl: './rainviewer.component.html',
  styleUrls: ['./rainviewer.component.scss']
})
export class RainviewerComponent implements OnInit {

  @Input()
  height = '80vh';
  @Input()
  minHeight = '85vh';
  options = {};
  timestamps = [];
  animationPosition = 0;
  playing: boolean;
  animationTimer: number;
  showTimestamp: Date;

  radarLayers: TileLayer[] = [];
  leafletLayers: TileLayer[] = [];

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.leafletLayers = this.leafletLayers.concat(
      tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        attribution: '<a target="_blank" href="https://openstreetmap.org">OpenStreetMap</a>'})
    );
    this.options = {
      zoom: 8,
      center: latLng(52.851445,6.5417552)
    };
    this.requestRadarData();
  }

  requestRadarData() {
    this.http.get<number[]>('https://api.rainviewer.com/public/maps.json').subscribe(ts => {
      this.timestamps = ts;
      this.animationPosition = this.timestamps.length - 1;
      this.showFrame(this.animationPosition);
      // this.play();
    })
  }

  showFrame(nextPosition: number) {
    this.changeRadarPosition(nextPosition);

    // preload next next frame (typically, +1 frame)
    // if don't do that, the animation will be blinking at the first loop
    let preloadPosition;
    if (nextPosition === this.timestamps.length) {
      // last position, loop back to 0
      preloadPosition = 0;
    } else {
      preloadPosition = nextPosition + 1;
    }

    this.changeRadarPosition(preloadPosition, true);
  }

  changeRadarPosition(position: number, preloadOnly = false) {
    while (position >= this.timestamps.length) {
      position -= this.timestamps.length;
    }
    while (position < 0) {
      position += this.timestamps.length;
    }
    let currentTimestamp = this.timestamps[this.animationPosition];
    let nextTimestamp = this.timestamps[position];

    this.addLayer(nextTimestamp);

    if (!preloadOnly) {

      // console.log('Set animationPosition to ' + position);
      this.animationPosition = position;

      // set current invisible
      this.radarLayers[currentTimestamp]?.setOpacity(0);

      // make next visible
      this.radarLayers[nextTimestamp].setOpacity(100);

      this.showTimestamp = new Date(nextTimestamp * 1000);
    } else {

      // only preload, make sure layer is invisible
      this.radarLayers[nextTimestamp]?.setOpacity(0);
    }
  }

  addLayer(timestamp: number) {
    if (!this.radarLayers[timestamp]) {
      this.radarLayers[timestamp] = tileLayer('https://tilecache.rainviewer.com/v2/radar/' + timestamp + '/256/{z}/{x}/{y}/7/0_1.png', {
        tileSize: 256,
        opacity: 0.8,
        zIndex: timestamp
      });
      this.leafletLayers = this.leafletLayers.concat(this.radarLayers[timestamp]);
    }
  }

  stop(): boolean {
    if (this.animationTimer) {
      clearInterval(this.animationTimer);
      this.animationTimer = undefined;
      this.playing = false;
      return true;
    } else {
      return false;
    }
  }

  play() {
    this.playing = true;
    this.showFrame(this.animationPosition);
    this.animationTimer = setInterval(() => {
      this.showFrame(this.animationPosition + 1)}
      , 600);
  }

  playStop() {
    if (!this.stop()) {
      this.play();
    }
  }

}
