import {Component, OnInit} from '@angular/core';
import {ChartModel} from "../model/chart-model.model";
import {Observable} from "rxjs";
import {HttpService} from "../http/http.service";
import {TitleHeaderService} from "../title-header/title-header.service";
import {ViewModeService} from "../view-mode/view-mode.service";
import {map, share, tap} from 'rxjs/operators';
import {ChartValues} from "../model/chart-values.model";
import {ChartModelWrapper} from "../model/chart-model-wrapper.model";
import {UntypedFormControl} from "@angular/forms";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-multi-model',
  templateUrl: './multi-model.component.html',
  styleUrls: ['./multi-model.component.scss']
})
export class MultiModelComponent implements OnInit {

  daySelect = new UntypedFormControl();
  dayFilter: string;

  dates: Observable<{date: string, label: string}[]>;

  multiModel: Observable<ChartModelWrapper<any>>;
  multiModelWindSpeed: Observable<ChartModelWrapper<any>>;
  multiModelWindDir: Observable<ChartModelWrapper<any>>;
  multiModelPrecipitation: Observable<ChartModelWrapper<any>>;
  multiModelCloudCover: Observable<ChartModelWrapper<any>>;
  multiModelLoading = {
    temperature: false,
    windSpeed: false,
    windDirection: false,
    precipitation: false,
    cloudCover: false
  };

  multiModelTempChart: Observable<ChartModel>;
  multiModelWindSpeedChart: Observable<ChartModel>;
  multiModelWindDirChart: Observable<ChartModel>;
  multiModelPrecipitationChart: Observable<ChartModel>;
  multiModelCloudCoverChart: Observable<ChartModel>;

  info = '<p> Deze pagina biedt een overzicht met de meerdaagse voorspellingen van alle grote weermodellen, specifiek voor de Midden-Drenthe\n' +
    '  (omgeving Beilen, Westerbork). De grafieken krijgen twee keer dag een update, vlak na het beschikbaar komen van de run van het ECMWF.\n' +
    '</p>'

  constructor(private httpService: HttpService,
              public viewModeService: ViewModeService,
              private titleHeaderService: TitleHeaderService,
              private datePipe: DatePipe) {
  }

  filterData(cm: ChartModel): ChartModel {
    if (!this.dayFilter) {
      return cm;
    }

    let indices = [];

    for (let i = 0; i < cm.labels.length; i++) {

      if (this.datePipe.transform(new Date(cm.labels[i]), 'yyyy-MM-dd') === this.dayFilter) {
        indices.push(i);
      }
    }

    cm.labels = indices.map(i => cm.labels[i]);

    cm.data.forEach(d => {
      d.values = indices.map(i => d.values[i]);
    })

    cm.timeUnit = 'hour';

    return cm;
  }

  extractChartData(wrapper: Observable<ChartModelWrapper<any>>): Observable<ChartModel> {
    return wrapper
      .pipe(map(w =>
        this.filterData(w.chartData)));
  }

  ngOnInit(): void {
    this.titleHeaderService.setTitle('Weermodellen voor Drenthe')
    this.getData(new Date());

    this.daySelect.valueChanges.subscribe(day => {
      this.dayFilter = day;

      this.multiModelTempChart = this.extractChartData(this.multiModel);
      this.multiModelPrecipitationChart = this.extractChartData(this.multiModelPrecipitation);
      this.multiModelCloudCoverChart = this.extractChartData(this.multiModelCloudCover);
      this.multiModelWindDirChart = this.extractChartData(this.multiModelWindDir);
      this.multiModelWindSpeedChart = this.extractChartData(this.multiModelWindSpeed);

    })
  }

  extractDates(wrapper: Observable<ChartModelWrapper<any>>) {
    this.dates = wrapper.pipe(
      map((c) => {
        return c.chartData.labels.map(d => {
          const date = new Date(d);
          const day = this.datePipe.transform(date, 'yyyy-MM-dd');
          const label = this.datePipe.transform(date, 'mediumDate');
          return {date: day, label: label};
        })
      }),
      map((arr: {date: string, label: string}[]) => {
        return [...new Map(arr.map(item =>
          [item['label'], item])).values()];
      })
    )
  }

  getData(beforeTimestamp: Date) {
    this.multiModel = this.httpService.getLatestMultiModel(beforeTimestamp)
      .pipe(tap(c => {
        c.chartData.data.unshift(this.getAvgLine(c.chartData.data));
        c.chartData.options = {
          scales: {
            y: {
              grid: {
                color: function(context) {
                  if (context.tick.value === 0) {
                    return '#000000';
                  }
                  return '#d3d3d3';
                },
                lineWidth: function(context) {
                  if (context.tick.value === 0) {
                    return 2;
                  }
                  return 1;
                }
              }
            }
          }
        }
      }),
        share());

    this.extractDates(this.multiModel);

    this.multiModelTempChart = this.extractChartData(this.multiModel);

    this.multiModelPrecipitation = this.httpService.getLatestMultiModelPrecipitation(beforeTimestamp)
      .pipe(tap(c => c.chartData.data.unshift(this.getAvgLine(c.chartData.data))),
        tap(data => {
          data.chartData.options = {
            scales: {
              y: {
                min: 0,
                suggestedMax: 5
              }
            }
          }
        }));
    this.multiModelPrecipitationChart = this.extractChartData(this.multiModelPrecipitation);

    this.multiModelCloudCover = this.httpService.getLatestMultiModelCloudCover(beforeTimestamp)
      .pipe(tap(c => c.chartData.data.unshift(this.getAvgLine(c.chartData.data))),
        map(data => {
          data.chartData.options = {
            scales: {
              y: {
                max: 100,
                min: 0
              }
            }
          }
          // fix odd case where cover percentage exceeds 100
          data.chartData.data.forEach(d => {
            for (let i = 0; i < d.values.length; i++) {
              if (d.values[i] > 100) {
                d.values[i] = 100;
              }
            }
          })
          return data;
        }));
    this.multiModelCloudCoverChart = this.extractChartData(this.multiModelCloudCover);

    this.multiModelWindDir = this.httpService.getLatestMultiModelWindDir(beforeTimestamp)
      .pipe(tap(c => c.chartData.data.unshift(this.getAvgLine(c.chartData.data))),
        map(data => {
          data.chartData.options = {
            scales: {
              y: {
                max: 360,
                min: 0,
                ticks: {
                  stepSize: 45,
                  callback: function (value) {
                    switch (value) {
                      case 0:
                        return 'N';
                      case 45:
                        return 'NO';
                      case 90:
                        return 'O';
                      case 135:
                        return 'ZO';
                      case 180:
                        return 'Z';
                      case 225:
                        return 'ZW';
                      case 270:
                        return 'W';
                      case 315:
                        return 'NW';
                      case 360:
                        return 'N';
                    }
                  }
                },
              }
            }
          }
          return data;
        }));
    this.multiModelWindDirChart = this.extractChartData(this.multiModelWindDir);

    this.multiModelWindSpeed = this.httpService.getLatestMultiModelWindSpeed(beforeTimestamp)
      .pipe(tap(c => c.chartData.data.unshift(this.getAvgLine(c.chartData.data))));
    this.multiModelWindSpeedChart = this.extractChartData(this.multiModelWindSpeed);

    this.multiModelLoading.temperature = true
    this.multiModelLoading.windDirection = true
    this.multiModelLoading.windSpeed = true
    this.multiModelLoading.precipitation = true
    this.multiModelLoading.cloudCover = true
  }

  get isLoading(): boolean {
    return ['temperature', 'windSpeed', 'windDirection', 'precipitation', 'cloudCover']
      .some(key => this.multiModelLoading[key])
  }

  multiModelLoadingEvent(loading: boolean, key: 'temperature' | 'windSpeed' | 'windDirection' | 'precipitation' | 'cloudCover') {
    this.multiModelLoading[key] = loading;
  }

  getAvgLine(chartValues: ChartValues[]): ChartValues {
    let avgValues: number[] = [];

    let index = 0;
    let max = Math.max(...chartValues.map(cv => cv.values.length));

    while (index < max) {

      let vs = chartValues
        .map(cv => cv.values[index])
        .filter(v => v != null);


      let avg = vs.reduce((a, b) => a + b, 0) / vs.length;

      avgValues.push(avg);

      index++;
    }

    let cv = new ChartValues();
    cv.color = 'black';
    cv.lineWidth = 1.8;
    cv.pointRadius = 0;
    cv.title = 'Avg';
    cv.values = avgValues;
    cv.showLine = true;
    cv.borderDash = [5, 2];

    return cv;
  }
}
