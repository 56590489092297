import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ChartModel} from "../model/chart-model.model";
import {HttpService} from "../http/http.service";
import {Observable} from "rxjs";
import {PhotoSet} from "../model/photo-set.model";

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  constructor(private http: HttpClient) { }

  getPhotoSet(): Observable<PhotoSet> {
    return this.http.get<PhotoSet>(HttpService.weatherPiUrl + '/api/media/photoset')

  }
}
