<div class="spinner" *ngIf="!_dailyAverages">
  <mat-spinner></mat-spinner>
</div>
<mat-card style="border: 1px solid #c8c8c8; margin-bottom: 3%" *ngIf="_dailyAverages">
  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef> Datum </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.date | date : 'dd-MM':'':'nl-NL'}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="averageTemperature">
      <mat-header-cell *matHeaderCellDef> Gem. temp (°C)</mat-header-cell>
      <mat-cell *matCellDef="let element" [style.backgroundColor]="textColor(element.averageTemperatureCelsius, 'averageTemperatureCelsius')">
        {{element.averageTemperatureCelsius | number :'1.0-1':'nl-NL' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="averageHumidity">
      <mat-header-cell *matHeaderCellDef> Gem. luchtv. (%)</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.averageHumidity | number :'1.0-1':'nl-NL' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="averageWindSpeed">
      <mat-header-cell *matHeaderCellDef> Gem. wind (km/u)</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.averageWindSpeed * 3.6 | number :'1.0-1':'nl-NL' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="dominantWindDirection">
      <mat-header-cell *matHeaderCellDef> Dom. windr.</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.dominantWindDirection }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="averagePressure">
      <mat-header-cell *matHeaderCellDef> Gem. luchtd. (hPa)</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.averagePressure | number :'1.0-1':'nl-NL'}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="averageSolarRadiation">
      <mat-header-cell *matHeaderCellDef> Gem. zon (W/m²)</mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.averageSolarRadiation | number :'1.0-1':'nl-NL'}}
      </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</mat-card>

