import { Injectable } from '@angular/core';
import {CompatClient, Stomp, StompSubscription} from "@stomp/stompjs";
import * as SockJS from 'sockjs-client';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  stompClient: CompatClient;
  stompSubscriptions = [];

  constructor() {}

  connect() {
    this.stompClient = Stomp.over(function(){
      return new SockJS('https://api.meteodrenthe.nl/wpi-socket')
    });
    this.stompClient.connect({}, function (frame) {
      console.log('Connected: ' + frame);
    });
    this.stompClient.debug = () => {};
  }

  subscribe(destination: string, callback: (body: any) => void) {
    const _this = this;
    this.waitForStompClientConnection(
      function () {
        const sub = _this.stompClient.subscribe(destination, function (current) {
          let parsed = JSON.parse(current.body);
          callback(parsed);
        });
        _this.stompSubscriptions.push({destination: destination, subscription: sub});
      }
    );
  }

  unsubscribe(destination: string) {
    this.stompSubscriptions.find((s) => s.destination === destination)?.subscription?.unsubscribe();
  }

  waitForStompClientConnection(callback) {
    if (!this.stompClient) {
      this.connect();
    }

    const _this = this;
    setTimeout(function () {
      if (!_this.stompClient.connected) {
        _this.waitForStompClientConnection(callback);
      } else {
        callback();
      }
    }, 500);
  }
}
