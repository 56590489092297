<div [ngStyle]="{'min-height': minHeight}" style="font-family: Gravity, serif !important;">
  <div style="text-align: right; z-index: 2; position: relative; margin-left: 50px">
    <span style="
            margin-right: 10px;
            background-color: white;
            padding: 6px;
            font-weight: bolder;
            font-size: 14px;
            box-shadow: 0 1px 5px rgba(0,0,0,0.65);
            border-radius: 50%;
            display: inline-block;
            width: 18px;
            height: 18px; cursor: pointer"
          (click)="playStop()">
      <i style="margin-right: 2.5px; color: #212121" [ngClass]="playing ? 'fas fa-pause' : 'fas fa-play'"></i>
    </span>
    <span class="disable-select" style="
            margin-right: 10px;
            background-color: white;
            padding: 6px;
            font-weight: bolder;
            font-size: 14px;
            color: #212121;
            box-shadow: 0 1px 5px rgba(0,0,0,0.65);
            border-radius: 4px;
            cursor: pointer"
           (click)="stop(); this.showFrame(animationPosition + 1)">
      {{showTimestamp | date:'HH:mm': '': 'nl-NL'}}
    </span>
  </div>
  <div [ngStyle]="{'height': height}"
       leaflet
       style="z-index: 1; border: 1px solid grey; border-radius: 4px; position: relative; margin-top: -38px; box-shadow:  0 3px 2px 0px rgba(0, 0, 0, 0.2), 0 0px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);"
       [leafletOptions]="options"
       [leafletLayers]="leafletLayers">
  </div>
</div>
