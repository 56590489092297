import {Component, Input, OnInit} from '@angular/core';
import {Forecast} from "../../model/forecast.model";

@Component({
  selector: 'app-forecast-wind',
  templateUrl: './forecast-wind.component.html',
  styleUrls: ['./forecast-wind.component.scss']
})
export class ForecastWindComponent implements OnInit {

  @Input()
  forecast: Forecast;
  @Input()
  direction: 'horizontal' | 'vertical' = 'horizontal';

  constructor() { }

  ngOnInit(): void {
  }

  getMaxByKeys(forecast: Forecast, keys: string[]) {
    let vals: number[] = keys.map(k => forecast[k]).filter(v => !!v);
    return Math.max(...vals);
  }

  convertWindDirectionString(direction: string) {
    if (!direction) {
      return null;
    }
    return direction.replace(/E/gi, 'O').replace(/S/gi, 'Z');
  }

}
