<div class="spinner" *ngIf="!initialized">
  <mat-spinner></mat-spinner>
</div>
<div class="tile-container" *ngIf="initialized && !viewModeService.mobileViewMode">
  <div class="weather-tile">
    <app-multitile [data]="temperatureTile" [digits]="'1.0-2'"></app-multitile>
  </div>
  <div class="weather-tile">
    <app-multitile [data]="windTile"></app-multitile>
  </div>
  <div class="weather-tile">
    <app-multitile [data]="rainTile"></app-multitile>
  </div>
  <div class="weather-tile">
    <app-multitile [data]="pressureTile"></app-multitile>
  </div>
  <div class="weather-tile">
    <app-multitile [data]="radiationTile"></app-multitile>
  </div>
  <div class="weather-tile">
    <app-multitile [data]="airQualityTile"></app-multitile>
  </div>
</div>

<div *ngIf="initialized && viewModeService.mobileViewMode" style="padding-top: 5px;">
  <app-mobile-dashboard [tileData]="[temperatureTile, humidityTile, windTile, rainTile, pressureTile, radiationTile, airQualityTile]"></app-mobile-dashboard>
</div>
