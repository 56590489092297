<div class="nav-bar"
  style="
height: 70px;
background-color: white;
box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
border-bottom: 1px solid grey;">
  <div class="logo-element" style="cursor:pointer;" (click)="navigate('dashboard')">
    <img style="padding-top: 2px" alt="Meteodrenthe logo" height="45px" width="60px" src="../../assets/images/header/md_icon_colors_large.png">
    <div class="title-element">
      <p>
        <span style="font-family: Gravity,serif">meteo</span>
        <span style="font-family: Gravity-Light,serif">drenthe</span>
      </p>
    </div>
  </div>
  <div style="display: flex; justify-content: space-evenly; width: 40%" *ngIf="screenWidth > 1100">
    <div class="menu-element">
      <a routerLink="dashboard" routerLinkActive="active">
        dashboard
      </a>
    </div>
    <div class="menu-element">
      <a routerLink="grafieken" routerLinkActive="active">
        grafieken
      </a>
    </div>
    <div class="menu-element">
      <a routerLink="radar" routerLinkActive="active">
        radar
      </a>
    </div>
    <div class="menu-element">
      <a routerLink="statistieken" routerLinkActive="active">
        statistieken
      </a>
    </div>
    <div class="menu-element">
      <a routerLink="weermodellen" routerLinkActive="active">
        weermodellen
      </a>
    </div>
<!--    <div class="menu-element">-->
<!--      <a routerLink="wolken" routerLinkActive="active">-->
<!--        wolken-->
<!--      </a>-->
<!--    </div>-->
    <div class="menu-element">
      <a routerLink="sensoren" routerLinkActive="active">
        sensoren
      </a>
    </div>
        <div class="menu-element">
          <a routerLink="galerij" routerLinkActive="active">
            galerij
          </a>
        </div>
    <div class="menu-element">
      <a routerLink="weercam" routerLinkActive="active">
        weercam
      </a>
    </div>
    <div class="menu-element">
      <a href="https://blog.meteodrenthe.nl" target="_blank">
        blog
      </a>
    </div>
  </div>
  <div class="menu-element">
    <a>
      <i class="fas fa-bars" style="cursor: pointer; color: black; font-size: 20px" [matMenuTriggerFor]="menu"></i>
    </a>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="navigate('')">dashboard</button>
      <button mat-menu-item (click)="navigate('grafieken')">grafieken</button>
      <button mat-menu-item (click)="navigate('radar')">radar</button>
      <button mat-menu-item (click)="navigate('statistieken')">statistieken</button>
      <button mat-menu-item (click)="navigate('weermodellen')">weermodellen</button>
      <button mat-menu-item (click)="navigate('sensoren')">sensoren</button>
      <button mat-menu-item (click)="navigate('galerij')">galerij</button>
      <button mat-menu-item (click)="navigate('weercam')">weercam</button>
      <button mat-menu-item (click)="navigate('https://blog.meteodrenthe.nl')">blog</button>

    </mat-menu>
  </div>
</div>
