<mat-card style="border: 1px solid #c8c8c8; margin-bottom: 3%; cursor:default;">
  <div id="timeunit">{{period}}<span *ngIf="period === '2020'" style="font-size: 12px; padding-left: 5px">(sinds maart)</span></div>
  <div class="spinner" *ngIf="!averages || !extremes">
    <mat-spinner></mat-spinner>
  </div>
  <div *ngIf="averages && extremes" style="display: flex; width: 100%">
    <div id="titles" *ngIf="screenWidth > 650" style="display: flex; flex-direction: column; width: 25%; color: #777777">
      <div style="height: 1.2em"></div>
      <div class="padded">Temperatuur</div>
      <div class="padded">Luchtvochtigheid</div>
      <div class="padded">Luchtdruk</div>
      <div class="padded">Zon</div>
      <div class="padded">Wind</div>
      <div style="height: 50px;"></div>
      <div class="padded">Neerslag</div>
    </div>
    <div id="title-icons" *ngIf="screenWidth <= 650" style="display: flex; flex-direction: column; width: 10%; color: #777777">
      <div style="height: 1.2em"></div>
      <div class="padded"><i class="wi wi-thermometer"></i></div>
      <div class="padded"><i class="wi wi-humidity"></i></div>
      <div class="padded"><i class="wi wi-barometer"></i></div>
      <div class="padded"><i class="wi wi-hot"></i></div>
      <div class="padded"><i class="wi wi-strong-wind"></i></div>
      <div style="height: 50px;"></div>
      <div class="padded"><i class="wi wi-raindrops"></i></div>
    </div>
    <div style="display: flex" [style.width.%]="screenWidth > 650 ? 75 : 90">
      <div id="averages" style="display: flex; flex-direction: column; width: 20%">
        <div style="color: #777777">gem</div>
        <div class="padded">{{averages.averageTemperatureCelsius ? (averages.averageTemperatureCelsius | number :'1.0-1':'nl-NL') : '--'}} </div>
        <div class="padded">{{averages.averageHumidity ? (averages.averageHumidity | number :'1.0-1':'nl-NL') : '--'}}</div>
        <div class="padded">{{averages.averagePressure ? (averages.averagePressure | number :'1.0-1':'nl-NL') : '--'}}</div>
        <div class="padded">{{averages.averageSolarRadiation ? (averages.averageSolarRadiation | number :'1.0-1':'nl-NL') : '--'}}</div>
        <div class="padded">{{averages.averageWindSpeed ? (averages.averageWindSpeed * 3.6 | number :'1.0-1':'nl-NL') : '--'}}</div>
        <div class="padded" style="color: #777777">totaal</div>
        <div class="padded">{{rainSum | number : '1.0-1' : 'nl-NL'}}</div>
      </div>
      <div id="extremes" style="display: flex; flex-direction: column; width: 70%">
        <div style="color: #777777; display: flex">
          <div style="width: 50%; text-align: center; padding-left: 7%">max</div>
          <div style="width: 50%; text-align: center; padding-left: 7%">min</div>
        </div>
        <div class="flex">
          <div class="padded w50 flex">
            <div class="w60 right-padded">{{extremes.maxTemperature.value | number :'1.1-1':'nl-NL'}}</div>
            <div class="w40 timestamp" [title]="extremes.maxTemperature.timestamp | date : 'dd-MM-yyyy HH:mm:ss':'nl-Nl'">
              {{extremes.maxTemperature.timestamp | date : 'dd-MM':'':'nl-NL' }}</div>
          </div>
          <div class="padded w50 flex">
            <div class="w60 right-padded">{{extremes.minTemperature.value | number :'1.1-1':'nl-NL'}}</div>
            <div class="w40 timestamp" [title]="extremes.minTemperature.timestamp | date : 'dd-MM-yyyy HH:mm:ss':'nl-Nl'">
              {{extremes.minTemperature.timestamp | date : 'dd-MM':'':'nl-NL' }}</div>
          </div>
        </div>
        <div class="flex">
          <div class="padded w50 flex">
            <div class="w60 right-padded">{{extremes.maxHumidity.value | number :'1.0-1':'nl-NL'}}</div>
            <div class="w40 timestamp" [title]="extremes.maxHumidity.timestamp | date : 'dd-MM-yyyy HH:mm:ss':'nl-Nl'">
              {{extremes.maxHumidity.timestamp | date : 'dd-MM':'':'nl-NL' }}</div>
          </div>
          <div class="padded w50 flex">
            <div class="w60 right-padded">{{extremes.minHumidity.value | number :'1.0-1':'nl-NL'}}</div>
            <div class="w40 timestamp" [title]="extremes.minHumidity.timestamp | date : 'dd-MM-yyyy HH:mm:ss':'nl-Nl'">
              {{extremes.minHumidity.timestamp | date : 'dd-MM':'':'nl-NL' }}</div>
          </div>
        </div>
        <div class="flex">
          <div class="padded w50 flex">
            <div class="w60 right-padded">{{extremes.maxPressure.value | number :'1.0-1':'nl-NL'}}</div>
            <div class="w40 timestamp" [title]="extremes.maxPressure.timestamp | date : 'dd-MM-yyyy HH:mm:ss':'nl-Nl'">
              {{extremes.maxPressure.timestamp | date : 'dd-MM':'':'nl-NL' }}</div>
          </div>
          <div class="padded w50 flex">
            <div class="w60 right-padded">{{extremes.minPressure.value | number :'1.0-1':'nl-NL'}}</div>
            <div class="w40 timestamp" [title]="extremes.minPressure.timestamp | date : 'dd-MM-yyyy HH:mm:ss':'nl-Nl'">
              {{extremes.minPressure.timestamp | date : 'dd-MM':'':'nl-NL' }}</div>
          </div>
        </div>
        <div class="flex">
          <div class="padded w50 flex">
            <div class="w60 right-padded">{{extremes.maxSolarRadiation.value | number :'1.0-1':'nl-NL'}}</div>
            <div class="w40 timestamp" [title]="extremes.maxSolarRadiation.timestamp | date : 'dd-MM-yyyy HH:mm:ss':'nl-Nl'">
              {{extremes.maxSolarRadiation.timestamp | date : 'dd-MM':'':'nl-NL' }}
            </div>
          </div>
          <div class="padded w50 flex">
            <div class="w60 right-padded"> - -</div>
            <div class="w40 timestamp">- -</div>
          </div>
        </div>
        <div class="flex">
          <div class="padded w50 flex">
            <div class="w60 right-padded">{{extremes.maxWindSpeed.value * 3.6 | number :'1.0-1':'nl-NL'}}</div>
            <div class="w40 timestamp" [title]="extremes.maxWindSpeed.timestamp | date : 'dd-MM-yyyy HH:mm:ss':'nl-Nl'">
              {{extremes.maxWindSpeed.timestamp | date : 'dd-MM':'':'nl-NL' }}</div>
          </div>
          <div class="padded w50 flex">
            <div class="w60 right-padded"> - -</div>
            <div class="w40 timestamp"> - -</div>
          </div>
        </div>
      </div>
      <div id="units" style="display: flex; flex-direction: column; width: 10%">
        <div style="height: 1.2em"></div>
        <div class="padded"><span>°C</span></div>
        <div class="padded"><span>%</span></div>
        <div class="padded"><span>hPa</span></div>
        <div class="padded"><span>W/m²</span></div>
        <div class="padded"><span>km/u</span></div>
        <div style="padding-top: 3em"></div>
        <div class="padded"><span>mm</span></div>
      </div>
    </div>
  </div>
</mat-card>
