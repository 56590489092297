import {Component, Input, OnInit} from '@angular/core';
import {Forecast} from "../../model/forecast.model";

@Component({
  selector: 'app-forecast-precip-chance',
  templateUrl: './forecast-precip-chance.component.html',
  styleUrls: ['./forecast-precip-chance.component.scss']
})
export class ForecastPrecipChanceComponent implements OnInit {

  @Input()
  forecast: Forecast;

  constructor() { }

  ngOnInit(): void {
  }

  precipChance(): number {
    return Math.max(this.forecast.dayTimePrecipChance, this.forecast.nightTimePrecipChance);
  }

}
