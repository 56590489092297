import { Component, OnInit } from '@angular/core';
import {TitleHeaderService} from "../title-header/title-header.service";
import {ViewModeService} from "../view-mode/view-mode.service";

@Component({
  selector: 'app-radar',
  templateUrl: './radar.component.html',
  styleUrls: ['./radar.component.scss']
})
export class RadarComponent implements OnInit {

  constructor(private titleHeaderService: TitleHeaderService,
              public viewModeService: ViewModeService) { }

  ngOnInit() {
    this.titleHeaderService.setTitle("Radar");
  }

}
