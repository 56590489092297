import { Injectable } from '@angular/core';
import {ViewMode} from "../enums/view-mode.enum";
import {Observable, ReplaySubject} from "rxjs";
import {DeviceDetectorService} from "ngx-device-detector";
import {CookieService} from "ngx-cookie-service";

@Injectable({
  providedIn: 'root'
})
export class ViewModeService {

  public viewModeChecked = false;
  private _viewMode: ViewMode;
  // private viewModeSubject = new ReplaySubject<ViewMode>();
  public isMobile: boolean;
  // public viewMode$: Observable<ViewMode>;
  private readonly viewModeCookie: string;

  constructor(private deviceDetectorService: DeviceDetectorService,
              private cookieService: CookieService) {
    this.viewModeCookie = this.cookieService.get('meteodrenthe-viewmode');
    // this.viewMode$ = this.viewModeSubject.asObservable()
    this._viewMode = this.initValue();
    this.viewModeChecked = true;
  }

  public initValue(): ViewMode {
    this.isMobile = this.deviceDetectorService.isMobile();
    let viewMode;
    if (this.viewModeCookie) {
      const numeric = +this.viewModeCookie;
      viewMode = numeric === 0 ? ViewMode.Desktop : ViewMode.Mobile;
    } else {
      viewMode = this.isMobile ? ViewMode.Mobile : ViewMode.Desktop;
    }
    return viewMode;
  }

  get mobileViewMode(): boolean {
    return this._viewMode as ViewMode === ViewMode.Mobile as ViewMode;
  }

  get viewMode(): ViewMode {
    return this._viewMode;
  }

  updateViewMode(viewMode: ViewMode) {
    const numeric = viewMode.valueOf();
    this.cookieService.set('meteodrenthe-viewmode', `${numeric}`)
    this._viewMode = viewMode;
    // this.viewModeSubject.next(viewMode);
  }
}
