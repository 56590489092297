<div style="width: 100%; display: flex; justify-content: center;">
  <div style="display: flex; min-width: 100px; ">
    <img src="../../assets/weather-icons/meteocons/fill/svg-static/raindrop-measure.svg"
         [height]="direction === 'horizontal' ? 37 : 47">
    <div [style.font-size.px]="direction === 'horizontal' ? 15 : 18"
         style=" display: flex; justify-content: center; margin: auto 0;">
      <span>{{getPrecipSum(forecast)}}</span> <span style="font-size: 15px; margin: auto 0 1px 1px;">mm</span>
    </div>
  </div>
</div>
