<mat-card>

  <div class="title">Wolkendek</div>

  <div class="cloud-layer-title">Hoog</div>
  <div style="height: 250px;">
    <div class="flex high-clouds" style="margin: 15px 0;">
      <div *ngFor="let l of highClouds; let i = index"
           [id]="'high-' + i" [style.width.%]="100 / highClouds.length"
           (mouseenter)="toggleHighlightElement(i, true)"
           (mouseleave)="toggleHighlightElement(i, false)"
           style="height: 40px"
           [style.background-color]="'rgba(169,169,169,' + l / 100">
        <div [id]="'coverage-high-' + i" style="margin-top: -20px; position: absolute; font-size: 15px; display: none">{{l}}%</div>
      </div>
    </div>

    <div class="cloud-layer-title">Midden</div>
    <div class="flex medium-clouds" style="margin: 15px 0;">
      <div *ngFor="let l of mediumClouds; let i = index"
           [id]="'medium-' + i" [style.width.%]="100 / mediumClouds.length"
           (mouseenter)="toggleHighlightElement(i, true)"
           (mouseleave)="toggleHighlightElement(i, false)"
           style="height: 40px;"
           [style.background-color]="'rgba(169,169,169,' + l / 100">
        <div [id]="'coverage-medium-' + i" style="margin-top: -20px; position: absolute; font-size: 15px; display: none">{{l}}%</div>
      </div>
    </div>

    <div class="cloud-layer-title">Laag</div>
    <div class="flex low-clouds" style="margin: 15px 0;">
      <div *ngFor="let l of lowClouds; let i = index"
           [id]="'low-' + i" [style.width.%]="100 / lowClouds.length"
           (mouseenter)="toggleHighlightElement(i, true)"
           (mouseleave)="toggleHighlightElement(i, false)"
           style="height: 40px;"
           [style.background-color]="'rgba(169,169,169,' + l / 100">
        <div [id]="'coverage-low-' + i" style="margin-top: -20px; position: absolute; font-size: 15px; display: none">{{l}}%</div>
      </div>
    </div>

    <div class="flex w100">
      <div *ngFor="let d of timestamps; let i = index;" [style.width.%]="100 / timestamps.length * 100">
        <div class="flex" [id]="'date-' + i" style="flex-direction: column; width: 200px; text-align: center; padding: 10px;" [style.display]="'none'">
          <div>{{d}}</div>
        </div>
      </div>
    </div>
  </div>

</mat-card>
