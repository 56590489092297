<div style="display: flex; flex-direction: column; justify-content: center; height: 100%; margin: 0 7px;">
  <div style="display: flex;">
    <div style="width: 50%; height: 100%; display: flex; justify-content: flex-end;">
      <div *ngIf="forecast.dayTimeTemp != null"
           style="background-color: #88b7df78; border-top-left-radius: 4px; border-bottom-left-radius: 4px;  margin: 5px 0; display: flex; justify-content: center; align-items: center;">
        <img [src]="'../../assets/weather-icons/meteocons/fill/svg-static/' + getWeatherIcon(forecast.dayTimeIconCode, forecast.dayTimeCloudCover, 'day')"
             width="100%" height="100%" [style.max-height.px]="maxHeightPx">

      </div>
    </div>

    <div style="width: 50%; height: 100%; display: flex; justify-content: flex-start;">
      <div *ngIf="forecast.nightTimeTemp != null"
           style="background-color: #193f57a6; border-top-right-radius: 4px; border-bottom-right-radius: 4px; margin: 5px 0; display: flex; justify-content: center; align-items: center;">
        <img [src]="'../../assets/weather-icons/meteocons/fill/svg-static/' + getWeatherIcon(forecast.nightTimeIconCode, forecast.nightTimeCloudCover, 'night')"
             width="100%" height="100%" [style.max-height.px]="maxHeightPx">
      </div>
    </div>
  </div>
</div>
