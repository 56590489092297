<div class="footer">
  <div class="footer-container" style="margin: 25px; display: flex; justify-content: space-between; height: 475px;">
    <div class="logo-container" style="width: 25%;">
      <div class="logo-container">
        <span class="helper"></span>
        <a href="https://davisinstruments.com" target="_blank">
          <img class="logo" alt="Davis Instruments logo" src="../../assets/images/footer/davis.png">
        </a>
      </div>
      <div class="logo-container">
        <span class="helper"></span>
        <a href="https://baranidesign.com" target="_blank">
          <img class="logo" alt="Barani Design logo" src="../../assets/images/footer/barani2.png">
        </a>
      </div>
      <div class="logo-container">
        <span class="helper"></span>
        <a href="https://raspberrypi.com" target="_blank">
          <img class="logo" alt="Raspberry Pi logo" src="../../assets/images/footer/raspberrypi.png">
        </a>
      </div>
      <div class="logo-container">
        <span class="helper"></span>
        <a href="https://adafruit.com" target="_blank">
          <img class="logo" alt="Adafruit logo" src="../../assets/images/footer/adafruit_logo_small.png">
        </a>
      </div>
      <div class="logo-container">
        <span class="helper"></span>
        <a href="https://www.arduino.cc" target="_blank">
          <img class="logo" alt="Arduino logo" src="../../assets/images/footer/arduino.png">
        </a>
      </div>
      <div class="logo-container">
        <span class="helper"></span>
        <a href="https://www.sensirion.com/en/" target="_blank">
          <img class="logo" alt="Sensirion logo" src="../../assets/images/footer/sensirion.png">
        </a>
      </div>
      <div class="logo-container">
        <span class="helper"></span>
        <a href="https://voltaicsystems.com/" target="_blank">
          <img class="logo" alt="Voltaic logo" src="../../assets/images/footer/voltaic.png">
        </a>
      </div>
    </div>
    <div class="menu-container" style="width: 15%">
      <div class="title">
        links
      </div>
      <div class="element">
        <a href="https://weerhuisje.nl" target="_blank">weerhuisje.nl</a>
      </div>
      <div class="element">
        <a href="https://wxforum.net" target="_blank">
          wxforum.net
        </a>
      </div>
      <div class="element">
        <a href="https://www.wunderground.com/dashboard/pws/IBEILE2" target="_blank">wunderground.com</a>
      </div>
      <div class="element">
        <a href="https://rainviewer.com" target="_blank">rainviewer.com</a>
      </div>
      <div class="element">
        <a href="https://angular.io" target="_blank">angular.io</a>
      </div>
      <div class="element">
        <a href="https://weerstatistieken.nl/hoogeveen" target="_blank">weerstatistieken.nl</a>
      </div>
      <div class="element">
        <a href="https://wxcharts.com" target="_blank">wxcharts.com</a>
      </div>
      <div class="element">
        <a href="https://weerplaza.nl" target="_blank">weerplaza.nl</a>
      </div>
      <div class="element">
        <a href="https://tinytronics.nl" target="_blank">tinytronics.nl</a>
      </div>
      <div class="element">
        <a href="https://www.vwkweb.nl" target="_blank">vwkweb.nl</a>
      </div>
      <div class="element">
        <a href="https://wetterzentrale.de" target="_blank">wetterzentrale.de</a>
      </div>
      <div class="element">
        <a href="https://flickr.com" target="_blank">flickr.com</a>
      </div>
    </div>
    <div class="menu-container md-icon" style="width: 20%">
      <img style="margin-top: 40px" alt="Meteodrenthe logo large" src="../../assets/images/header/md_icon_colors_large.png" height="150px"
           width="200px"/>
      <div style="font-size: 30px">
        <span style="font-family: Gravity,serif">meteo</span>
        <span style="font-family: Gravity-Light,serif">drenthe</span>
      </div>
    </div>
    <div class="menu-container" style="width: 15%">
      <div class="title">
        website
      </div>
      <div class="element">
        <a routerLink="dashboard" routerLinkActive="active">
          dashboard
        </a>
      </div>
      <div class="element">
        <a routerLink="grafieken" routerLinkActive="active">
          grafieken
        </a>
      </div>
      <div class="element">
        <a routerLink="radar" routerLinkActive="active">
          radar
        </a>
      </div>
      <div class="element">
        <a routerLink="statistieken" routerLinkActive="active">
          statistieken
        </a>
      </div>
      <div class="element">
        <a routerLink="weermodellen" routerLinkActive="active">
          weermodellen
        </a>
      </div>
      <div class="element">
        <a routerLink="sensoren" routerLinkActive="active">
          sensoren
        </a>
      </div>
      <div class="element">
        <a routerLink="galerij" routerLinkActive="active">
          galerij
        </a>
      </div>
      <div class="element">
        <a routerLink="weercam" routerLinkActive="active">
          weercam
        </a>
      </div>
      <div class="element">
        <a href="https://blog.meteodrenthe.nl" target="_blank">
          blog
        </a>
      </div>
    </div>
    <div class="menu-container" style="position: relative;">
      <div class="title">
        informatie
      </div>
      <div class="element">
        <a href="mailto:contact@meteodrenthe.nl">contact</a>
      </div>
<!--      <div class="element">-->
<!--        <a routerLink="info" routerLinkActive="active">-->
<!--          over meteodrenthe.nl-->
<!--        </a>-->
<!--      </div>-->
            <div class="element">
              <a href="https://twitter.com/meteodrenthe" target="_blank">
                twitter
                <img class="icon" width="20" alt="Twitter logo" src="../../assets/images/footer/twitter-official.svg">
              </a>
            </div>
      <span style="position: absolute; bottom: 20px">
        © <span style="font-family: Gravity,serif">meteo</span><span
        style="font-family: Gravity-Light,serif">drenthe</span>
        {{now | date:'yyyy'}}</span>
    </div>
  </div>
</div>
