import {Component, Input, OnInit} from '@angular/core';
import {TileData} from "../../model/tile-data.model";
import {DatePipe, DecimalPipe} from '@angular/common';

@Component({
  selector: 'app-multitile',
  templateUrl: './multitile.component.html',
  styleUrls: ['./multitile.component.scss']
})
export class MultitileComponent implements OnInit {

  @Input()
  data: TileData;
  decimalPipe = new DecimalPipe('nl-NL');
  datePipe = new DatePipe('nl-NL');
  @Input()
  digits: string = '1.0-1';

  constructor() { }

  ngOnInit() {
  }

  transform (value: any, dateFormat?: string) {
    if (value === null) {
      return null;
    }

    if (typeof value === 'number') {
      value = this.decimalPipe.transform(value, this.digits);
    } else if (new Date(value).toString() !== 'Invalid Date') {
      value = this.datePipe.transform(value, dateFormat, '', 'nl-NL')
    }
    return value;
  }

  trendIcon(trend: string) {
    if (trend === 'RISING') {
      return 'wi wi-direction-up-right delta-icon'
    } else if (trend === 'DROPPING') {
      return 'wi wi-direction-down-right delta-icon';
    } else {
      return 'no-icon';
    }
  }

  colorByKey(key: string) {
    let color = 'black';
    switch (key) {
      case 'Maximum' : color = '#7d00da';
      break;
      case 'Minimum' : color = '#0090ff';
      break;
      case 'Maandsom' : color = '#7d00da';
    }
    return color;
  }

}
