import {Component, Input, OnInit} from '@angular/core';
import {Sensor} from "../model/sensor.model";
import {ViewModeService} from "../view-mode/view-mode.service";

@Component({
  selector: 'app-sensor-description-list',
  templateUrl: './sensor-description-list.component.html',
  styleUrls: ['./sensor-description-list.component.scss'],
  host: {
  '(window:resize)': 'onResize($event)'
  }
})
export class SensorDescriptionListComponent implements OnInit {

  _sensors: Sensor[];

  @Input()
  hide: boolean;

  screenWidth: number;

  constructor(public viewModeService: ViewModeService) { }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
  }

  @Input()
  set sensors(sensors: Sensor[]) {
    sensors?.sort((a, b) => b.id - a.id);
    this._sensors = sensors;
  }

  onResize(event) {
    this.screenWidth = event.target.innerWidth; // window width
  }

}
