import {Component, OnInit} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";
import {ViewModeService} from "./view-mode.service";
import {ViewMode} from "../enums/view-mode.enum";

@Component({
  selector: 'app-view-mode',
  templateUrl: './view-mode.component.html',
  styleUrls: ['./view-mode.component.scss']
})
export class ViewModeComponent implements OnInit {

  ViewMode = ViewMode;
  viewMode: UntypedFormControl;

  constructor(private viewModeService: ViewModeService) {
    // doesnt work without ternary operator check
    this.viewMode = new UntypedFormControl(viewModeService.viewMode.valueOf() === 0 ? ViewMode.Desktop : ViewMode.Mobile);
  }

  ngOnInit(): void {
    this.viewMode.valueChanges.subscribe((vm) => {
      this.viewModeService.updateViewMode(vm)
    });
  }

}
