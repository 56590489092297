import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as uuid from 'uuid';
import {
  BarController, BarElement,
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  PointElement,
  TimeScale,
  Title, Tooltip
} from "chart.js";
import {DatePipe, formatNumber} from "@angular/common";

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {

  _chartData: any;
  containerId = uuid.v4();
  barChart: Chart;

  @Output()
  loadingEvent = new EventEmitter<boolean>();

  constructor(private datePipe: DatePipe) { }

  @Input()
  set chartData(chartData: any) {
    this._chartData = chartData;

    if (chartData) {
      this.loadingEvent.emit(true);

      this.barChart?.destroy();


      let dataSets = JSON.parse(JSON.stringify(this._chartData.data))

      dataSets.forEach(d => {
        let sensor = this._chartData.sensors.find(s => s.id === d.sensorEntityId);
        d.backgroundColor = sensor.color;
        d.fill = false;
        d.pointHoverRadius = 6;
        d.pointHitRadius = 16;
        d.label = sensor.description;
        d.barThickness = 240 / this._chartData.labels.length;
      })

      let ctx = <HTMLCanvasElement>document.getElementById(this.containerId); // node

      console.log(this._chartData.labels);
      const barChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this._chartData.labels,
          datasets: dataSets
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          animation: false,
          layout: {
            padding: {
              left: 2,
              bottom: 2
            }
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: (context) => {
                  return formatNumber(context.raw as number, 'nl-NL') + 'mm' + ' | ' + context.dataset.label;
                }
              }
            },
            title: {
              display: true,
              text: 'Neerslag',
              align: 'start',
              padding: {
                top: 5,
                bottom: 18
              },
              color: 'black',
              font: {
                family: 'Gravity, serif',
                size: 18,
                weight: 'normal'
              }
            },
            legend: {
              // display: this.displayLegend,
              // onClick: legendClickFn,
              labels: {
                boxWidth: 17,
                color: 'black',
                font: {
                  family: 'Gravity, serif'
                }
              }
            }
          },
          scales: {
            x: {
              display: true,
              type: 'time',
              time: {
                tooltipFormat: 'HH:mm',
                unit: 'minute',
                displayFormats: {
                  second: 'HH:mm',
                  minute: 'HH:mm',
                  hour: 'HH:mm',
                  day: 'DD-MM'
                },
                stepSize: 20
              },
              ticks: {
                autoSkip: true,
                font: {
                  size: 12,
                  family: 'Gravity, serif'
                },
                callback: (value, index, ticks) => {
                  let date = new Date(ticks[index].value);
                  let format = 'HH:mm';
                  if (index === 0 || new Date(ticks[index - 1].value).getDay() !== date.getDay()) {
                    format = 'dd-MM-yy HH:mm'
                  }
                  return this.datePipe.transform(date, format, 'CET', 'nl-NL')
                },
                color: 'black',
                source: 'auto',
              }
            },
            y: {
              display: true,
              ticks: {
                font: {
                  size: 12,
                  family: 'Gravity, serif'
                },
                color: 'black',
                callback: function (value, index, values) {
                  return value + 'mm';
                }
              },
            },
          }
          // scales: {
          //   x: {
          //     display: true,
          //     type: 'time',
          //     time: {
          //       tooltipFormat: 'HH:mm',
          //       unit: 'hour',
          //       displayFormats: {
          //         second: 'HH:mm',
          //         minute: 'HH:mm',
          //         hour: 'HH:mm',
          //         day: 'DD-MM'
          //       },
          //       stepSize: 1
          //     },
          //     ticks: {
          //       autoSkip: true,
          //       font: {
          //         size: 12,
          //         family: 'Gravity, serif'
          //       },
          //       color: 'black',
          //       source: 'auto'
          //     }
          //   },
          //   y: {
          //     display: true,
          //     ticks: {
          //       font: {
          //         size: 12,
          //         family: 'Gravity, serif'
          //       },
          //       color: 'black',
          //       callback: function (value, index, values) {
          //         return value + 'mm';
          //       }
          //     },
          //   },
          // }
        }
      });

      this.barChart = barChart;
      this.loadingEvent.emit(false);
    }
  }

  ngOnInit() {
    Chart.register(BarController, BarElement, PointElement, LinearScale, Title, CategoryScale, TimeScale, Legend, Tooltip);
  }

}
