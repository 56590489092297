<div style="width: 100%;" *ngIf="forecasts">

    <div *ngIf="screenWidth > 1100" style="display: flex; justify-content: space-between">

      <div class="forecast-card-vertical" *ngFor="let f of forecasts">

        <div style="display: flex; height: 100%; justify-content: space-between; flex-direction: column;">

          <div style="height: 15%; display: flex;">

            <div style="width: 30%; margin: auto;">
              <img *ngIf="f.dayTimeThunderIndex > 0 || f.nightTimeThunderIndex > 0"
                   style="margin-left: 7px;" [src]="'../../assets/weather-icons/meteocons/fill/svg-static/lightning-bolt.svg'" [height]="38">
            </div>

            <app-forecast-date style="height: 15%; width: 40%; margin: auto; display: flex;" [forecast]="f" [direction]="'vertical'"></app-forecast-date>

            <div style="width: 30%; margin: auto;">
              <img *ngIf="f.uvIndex >= 7" [src]="'../../assets/weather-icons/meteocons/fill/svg-static/uv-index-' + f.uvIndex + '.svg'" [height]="38">
            </div>



          </div>

          <mat-divider></mat-divider>

          <app-forecast-icons style="height: 17%" [forecast]="f" [direction]="'vertical'"></app-forecast-icons>

          <mat-divider></mat-divider>

          <app-forecast-temps style="height: 20%" [forecast]="f"></app-forecast-temps>

          <mat-divider></mat-divider>

          <app-forecast-wind style="height: 11%" [forecast]="f" [direction]="'vertical'"></app-forecast-wind>

          <mat-divider></mat-divider>

          <app-forecast-precip style="height: 11%" [forecast]="f" [direction]="'vertical'"></app-forecast-precip>

          <mat-divider></mat-divider>

          <app-forecast-precip-chance style="height: 11%" [forecast]="f"></app-forecast-precip-chance>

          <mat-divider></mat-divider>

          <app-forecast-clouds style="height: 11%" [forecast]="f"></app-forecast-clouds>

        </div>

      </div>

    </div>

    <div *ngIf="screenWidth <= 1100">
      <div class="mobile-card" *ngFor="let f of forecasts">

        <div class="flex-between w100">

          <app-forecast-date style="width:18%" [forecast]="f"></app-forecast-date>

          <app-forecast-icons style="width:35%" [forecast]="f" [maxHeightPx]="60"></app-forecast-icons>

          <div style="width:22%; display: flex; justify-content: center;">
            <app-forecast-temps style="width: 90%" [forecast]="f"></app-forecast-temps>
          </div>

          <div style="width: 25%; display: flex; justify-content: center;">

            <div style="display: flex; flex-direction: column; height: 100%; justify-content: center;">

              <app-forecast-wind [forecast]="f"></app-forecast-wind>

              <app-forecast-precip [forecast]="f"></app-forecast-precip>

            </div>

          </div>

        </div>

      </div>
    </div>

</div>
