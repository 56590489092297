import {Component, Input, OnInit} from '@angular/core';
import {Forecast} from "../../model/forecast.model";

@Component({
  selector: 'app-forecast-precip',
  templateUrl: './forecast-precip.component.html',
  styleUrls: ['./forecast-precip.component.scss']
})
export class ForecastPrecipComponent implements OnInit {

  @Input()
  forecast: Forecast;
  @Input()
  direction: 'horizontal' | 'vertical' = 'horizontal';

  constructor() {
  }

  ngOnInit(): void {
  }

  getPrecipSum(forecast: Forecast): number {
    let sum = forecast.dayTimePrecipitation + forecast.nightTimePrecipitation;

    let places = sum >= 10 ? 0 : 1;

    return this.roundUp(sum, places);
  }

  roundUp(input: number, places: number): number {
    let multiplier = Math.pow(10, places);
    return Math.ceil(input * multiplier) / multiplier;
  }

}
