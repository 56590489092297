import {Component, Input, OnInit} from '@angular/core';
import {HttpService} from "../http/http.service";
import {Forecast} from "../model/forecast.model";
import {Observable} from "rxjs";
import {map, share} from "rxjs/operators";
import {AstroData} from "../model/astro-data.model";
import {CompatClient, Stomp} from "@stomp/stompjs";
import * as SockJS from 'sockjs-client';
import {ViewModeService} from "../view-mode/view-mode.service";


@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss']
})
export class WeatherComponent implements OnInit {

  forecasts$: Observable<Forecast[]>;
  astroData$: Observable<AstroData[]>;
  stompClient: CompatClient;

  constructor(private httpService: HttpService,
              public viewModeSerice: ViewModeService) {
  }

  ngOnInit() {
    this.connect();
    this.forecasts$ = this.httpService.getForecast().pipe(
      share());
    this.astroData$ = this.forecasts$.pipe(
      map(forecasts => forecasts
        .map(f => f.astroData).sort((a, b) => {
          let dateA = new Date(a.date), dateB = new Date(b.date);
          return dateA.getTime() - dateB.getTime();
        } )));
  }

  connect() {
    this.stompClient = Stomp.over(function(){
      return new SockJS('https://api.meteodrenthe.nl/wpi-socket')
    });
    this.stompClient.connect({}, function (frame) {
      console.log('Connected: ' + frame);
    });
  }

  disconnect() {
    if (this.stompClient != null) {
      this.stompClient.deactivate();
    }
    console.log('Disconnected!');
  }

}
