<mat-card style="border: 1px solid #c8c8c8; margin-bottom: 3%" *ngIf="!hide">

  <div class="title">Sensor beschrijving</div>

  <div>

    <mat-accordion displayMode="flat" multi>

      <mat-expansion-panel *ngFor="let s of _sensors" expanded="false">

        <mat-expansion-panel-header class="gravity" [style.font-size.px]="viewModeService.isMobile ? 10 : 16">

          <div class="w10">
            <div [style.background-color]="s.color"
                 [style.border-radius.%]="50"
                 [style.height.px]="18"
                 [style.width.px]="18">
            </div>
          </div>

          <div  [ngClass]="screenWidth > 1100 ? 'w30' : 'w50'" >
            {{s.description}}
          </div>

          <div [ngClass]="screenWidth > 1100 ? 'w20' : 'w40'">
            {{s.sensorModel}}
          </div>

          <div class="w20 bold-label" *ngIf="screenWidth > 1100">
            <span *ngIf="s.primarySensor">primary</span>
            <span *ngIf="s.testMode">testing</span>
          </div>

        </mat-expansion-panel-header>

        <p>
          {{s.sensorSummary || 'This sensor has no additional details'}}
        </p>

      </mat-expansion-panel>

    </mat-accordion>


  </div>
</mat-card>
