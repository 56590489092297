<div style="display: flex; justify-content: center; height: 100%;">
  <div style="height: 85%; width: 90%; max-width: 160px; display: flex; flex-direction: column; margin: 5px; ">

    <div #dayTimeTempContainer [style.border]="'2px solid '+ getTempColor(forecast.dayTimeTemp)" [style.color]="getTempColor(forecast.dayTimeTemp)"
         style="height: 50%; border-top-left-radius: 6px; border-top-right-radius: 6px; display: flex; justify-content: center; align-items: center;">
      <div class="w30" *ngIf="dayTimeTempContainer.offsetWidth > 115"></div>

      {{formatTemp(forecast.dayTimeTemp)}}

      <div class="w30 flex" *ngIf="dayTimeTempContainer.offsetWidth > 115">
        <img *ngIf="forecast.dayTimeTemp >= 35 && forecast.dayTimeTemp < 38" [src]="'../../assets/weather-icons/meteocons/fill/svg-static/code-orange.svg'" [height]="30">
        <img *ngIf="forecast.dayTimeTemp > 38" [src]="'../../assets/weather-icons/meteocons/fill/svg-static/code-red.svg'" [height]="30">

      </div>
    </div>
    <div [style.border]="'2px solid '+ getTempColor(forecast.nightTimeTemp)" [style.color]="getTempColor(forecast.nightTimeTemp)"
         style="margin-top: 2px; height: 50%; border-bottom-left-radius: 6px; border-bottom-right-radius: 6px; display: flex; justify-content: center; align-items: center;">
      {{formatTemp(forecast.nightTimeTemp)}}
    </div>

  </div>

</div>
