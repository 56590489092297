import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TilesComponent} from './tiles/tiles.component';
import {HeaderComponent} from './header/header.component';
import {MatCardModule} from '@angular/material/card';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from "@angular/common/http";
import {ChartsComponent} from './charts/charts.component';
import {BarChartComponent} from './charts/bar-chart/bar-chart.component';
import {InfoComponent} from './info/info.component';
import {WeatherComponent} from './weather/weather.component';
import {MatMenuModule} from "@angular/material/menu";
import localeNl from '@angular/common/locales/nl';
import {DatePipe, registerLocaleData} from "@angular/common";
import {DateheaderComponent} from './dateheader/dateheader.component';
import {MatDividerModule} from "@angular/material/divider";
import {MultitileComponent} from './tiles/multitile/multitile.component';
import {ChartjsComponent} from './charts/chartjs/chartjs.component';
import {WeathercamComponent} from './weathercam/weathercam.component';
import {ForecastComponent} from './forecast/forecast.component';
import {MatListModule} from "@angular/material/list";
import {MatButtonModule} from '@angular/material/button';
import {MatGridListModule} from "@angular/material/grid-list";
import {AstroComponent} from './astro/astro.component';
import {FooterComponent} from './footer/footer.component';
import {MatSelectModule} from "@angular/material/select";
import {RainviewerComponent} from './radar/rainviewer/rainviewer.component';
import {RadarComponent} from "./radar/radar.component";
import {LeafletModule} from "@asymmetrik/ngx-leaflet";
import {ExtremesComponent} from './statistics/extremes/extremes.component';
import {MatTableModule} from "@angular/material/table";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {TitleHeaderComponent} from './title-header/title-header.component';
import {AveragesComponent} from './statistics/averages/averages.component';
import {MonthYearSelectorComponent} from './shared/month-day-selector/month-year-selector.component';
import {StatisticsComponent} from './statistics/statistics.component';
import {PeriodSummaryComponent} from './statistics/period-summary/period-summary.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {StatisticsChartComponent} from './statistics/statistics-chart/statistics-chart.component';
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from "ngx-google-analytics";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {MatInputModule} from "@angular/material/input";
import {NgxMaterialTimepickerModule} from "ngx-material-timepicker";
import {MobileDashboardComponent} from './mobile-dashboard/mobile-dashboard.component';
import {MobileTileComponent} from './mobile-dashboard/mobile-tile/mobile-tile.component';
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {ViewModeComponent} from './view-mode/view-mode.component';
import {SensorsComponent} from './sensors/sensors.component';
import {MultiModelComponent} from './multi-model/multi-model.component';
import {EnsemblesComponent} from './ensembles/ensembles.component';
import {InfoCardComponent} from './info-card/info-card.component';
import {GalleryComponent} from './gallery/gallery.component';
import {GalleryModule} from "ng-gallery";
import {LightboxModule} from "ng-gallery/lightbox";
import { ForecastIconsComponent } from './forecast/forecast-icons/forecast-icons.component';
import { ForecastTempsComponent } from './forecast/forecast-temps/forecast-temps.component';
import { ForecastDateComponent } from './forecast/forecast-date/forecast-date.component';
import { ForecastWindComponent } from './forecast/forecast-wind/forecast-wind.component';
import { ForecastPrecipComponent } from './forecast/forecast-precip/forecast-precip.component';
import { ForecastPrecipChanceComponent } from './forecast/forecast-precip-chance/forecast-precip-chance.component';
import { ForecastCloudsComponent } from './forecast/forecast-clouds/forecast-clouds.component';
import { VisibilityComponent } from './visibility/visibility.component';
import {CloudsComponent} from "./visibility/clouds/clouds.component";
import { SensorDescriptionListComponent } from './sensor-description-list/sensor-description-list.component';
import {MatExpansionModule} from "@angular/material/expansion";
import { LoadingComponent } from './loading/loading.component';
import { GenericFunctionFilterPipe } from './generic-function-filter.pipe';

registerLocaleData(localeNl, 'nl-NL');

@NgModule({
  declarations: [
    AppComponent,
    TilesComponent,
    HeaderComponent,
    ChartsComponent,
    BarChartComponent,
    InfoComponent,
    WeatherComponent,
    DateheaderComponent,
    MultitileComponent,
    ChartjsComponent,
    WeathercamComponent,
    ForecastComponent,
    AstroComponent,
    FooterComponent,
    RainviewerComponent,
    RadarComponent,
    ExtremesComponent,
    TitleHeaderComponent,
    AveragesComponent,
    MonthYearSelectorComponent,
    StatisticsComponent,
    PeriodSummaryComponent,
    StatisticsChartComponent,
    MobileDashboardComponent,
    MobileTileComponent,
    ViewModeComponent,
    SensorsComponent,
    MultiModelComponent,
    EnsemblesComponent,
    InfoCardComponent,
    GalleryComponent,
    ForecastIconsComponent,
    ForecastTempsComponent,
    ForecastDateComponent,
    ForecastWindComponent,
    ForecastPrecipComponent,
    ForecastPrecipChanceComponent,
    ForecastCloudsComponent,
    CloudsComponent,
    VisibilityComponent,
    SensorDescriptionListComponent,
    LoadingComponent,
    GenericFunctionFilterPipe,
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatCardModule,
        MatMenuModule,
        MatDividerModule,
        MatListModule,
        MatGridListModule,
        MatButtonModule,
        MatSelectModule,
        MatTableModule,
        MatProgressSpinnerModule,
        LeafletModule,
        MatCheckboxModule,
        FormsModule,
        NgxGoogleAnalyticsModule.forRoot('G-1F9YPT41K8'),
        NgxGoogleAnalyticsRouterModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        ReactiveFormsModule,
        NgxMaterialTimepickerModule,
        MatButtonToggleModule,
        GalleryModule,
        LightboxModule.withConfig({
            panelClass: 'fullscreen'
        }),
        MatExpansionModule
    ],
  providers: [
    DatePipe,
    { provide: LOCALE_ID, useValue: "nl-NL" },
    {provide: MAT_DATE_LOCALE, useValue: 'nl-NL'}],
  bootstrap: [AppComponent]
})
export class AppModule {
}
