import { Component, OnInit } from '@angular/core';
import {HttpService} from "../http/http.service";
import {ViewModeService} from "../view-mode/view-mode.service";
import {TitleHeaderService} from "../title-header/title-header.service";
import {Observable} from "rxjs";
import {ChartModel} from "../model/chart-model.model";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-ensembles',
  templateUrl: './ensembles.component.html',
  styleUrls: ['./ensembles.component.scss']
})
export class EnsemblesComponent implements OnInit {

  ensembleGfsTemperature: Observable<ChartModel>;
  ensembleGfsPrecipitation: Observable<ChartModel>;
  ensembleGfsWindSpeed: Observable<ChartModel>;
  ensembleGfsWindGusts: Observable<ChartModel>;
  ensembleGfsWindDirection: Observable<ChartModel>;

  ensemblesLoading = {
    temperature: false,
    windSpeed: false,
    windDirection: false,
    precipitation: false,
    gusts: false
  };

  constructor(private httpService: HttpService,
              public viewModeService: ViewModeService,
              private titleHeaderService: TitleHeaderService) { }

  ngOnInit(): void {
    this.titleHeaderService.setTitle('GFS pluim voor Drenthe')

    this.ensembleGfsTemperature = this.httpService.getLatestGfsEnsembleTemperature();
    this.ensembleGfsPrecipitation = this.httpService.getLatestGfsEnsemblePrecipitation()
      .pipe(map(data => {
        data.options = {
          scales: {
            y: {
              min: 0,
              suggestedMax: 5
            }
          }
        }
        return data;
      }));
    this.ensembleGfsWindSpeed = this.httpService.getLatestGfsEnsembleWindSpeed();
    this.ensembleGfsWindGusts = this.httpService.getLatestGfsEnsembleGusts();
    this.ensembleGfsWindDirection = this.httpService.getLatestGfsEnsembleWindDirection()
      .pipe(map(data => {
        data.options = {
          scales: {
            y: {
              max: 360,
              min: 0,
              ticks: {
                stepSize: 45,
                callback: function (value, index, values) {
                  switch (value) {
                    case 0: return 'N';
                    case 45: return 'NO';
                    case 90: return 'O';
                    case 135: return 'ZO';
                    case 180: return 'Z';
                    case 225: return 'ZW';
                    case 270: return 'W';
                    case 315: return 'NW';
                    case 360: return 'N';
                  }
                }},
            }
          }
        }
        return data;
      }));

    this.ensemblesLoading.gusts = true;
    this.ensemblesLoading.windDirection = true;
    this.ensemblesLoading.windSpeed = true;
    this.ensemblesLoading.precipitation = true;
    this.ensemblesLoading.temperature = true;

  }

  get isLoading(): boolean {
    return ['temperature', 'windSpeed', 'windDirection', 'precipitation', 'cloudCover']
      .some(key => this.ensemblesLoading[key])
  }

  gfsEnsembleLoadingEvent(loading: boolean, key: 'temperature' | 'windSpeed' | 'windDirection' | 'precipitation' | 'gusts') {
    this.ensemblesLoading[key] = loading;
  }
}
