import {Component, Input, OnInit} from '@angular/core';
import {TileData} from "../model/tile-data.model";

@Component({
  selector: 'app-mobile-dashboard',
  templateUrl: './mobile-dashboard.component.html',
  styleUrls: ['./mobile-dashboard.component.scss']
})
export class MobileDashboardComponent implements OnInit {

  @Input()
  tileData: TileData[];

  constructor() { }

  ngOnInit(): void {
  }

}
