import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class HeaderComponent implements OnInit {

  screenWidth: number;

  constructor(private router: Router) { }

  ngOnInit() {
    this.screenWidth = window.innerWidth;
  }

  navigate(route: string) {
    if (route.startsWith('http')) {
      window.open(route);
    } else {
      this.router.navigate([route]);
    }
  }

  onResize(event){
    this.screenWidth = event.target.innerWidth; // window width
  }
}
