import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpService} from "../http/http.service";
import {TileData} from "../model/tile-data.model";
import {FullWeather} from "../model/full-weather.model";
import {Humidity} from "../model/humidity.model";
import {Temperature} from "../model/temperature.model";
import {Rain} from "../model/rain.model";
import {Wind} from "../model/wind.model";
import {Pressure} from "../model/pressure.model";
import {Radiation} from "../model/radiation.model";
import {CompatClient, StompSubscription} from "@stomp/stompjs";
import {DerivedTemperature} from "../model/derived-temperature.model";
import {TitleHeaderService} from "../title-header/title-header.service";
import {DatePipe} from "@angular/common";
import {AirQuality} from "../model/air-quality.model";
import {DeviceDetectorService} from "ngx-device-detector";
import {ViewModeService} from "../view-mode/view-mode.service";


@Component({
  selector: 'app-tiles',
  templateUrl: './tiles.component.html',
  styleUrls: ['./tiles.component.scss']
})
export class TilesComponent implements OnInit, OnDestroy {

  initialized = false
  temperatureTile: TileData;
  rainTile: TileData;
  windTile: TileData;
  pressureTile: TileData;
  radiationTile: TileData;
  airQualityTile: TileData;
  humidityTile: TileData;
  @Input()
  stompClient: CompatClient;
  lastUpdate: Date;
  updateHistory: FullWeather[] = [];
  baseTitle = "Lieving actueel";
  stompSubscription: StompSubscription;

  constructor(private httpService: HttpService,
              private titleHeaderService: TitleHeaderService,
              private datePipe: DatePipe,
              public viewModeService: ViewModeService) { }

  ngOnInit() {
    this.titleHeaderService.setTitle(this.baseTitle);
    this.httpService.fullWeather().subscribe((f: FullWeather) => {
      this.pushToHistory(f);
      this.setTiles(f);
      this.initialized = true;
    });
    this.subscribe();
  }

  pushToHistory(fullWeather: FullWeather) {
    const length = this.updateHistory.push(fullWeather);
    if (length > 20) {
      this.updateHistory.shift();
    }
  }

  getOldestHistory() {
    return this.updateHistory[0];
  }

  setTiles(f: FullWeather) {
    this.setTemperatureTile(f.temperature, f.humidity, f.derivedTemperature);
    if (f.airQuality) {
      this.setAirQualityTile(f.airQuality);
    }
    this.setRainTile(f.rain);
    this.setWindTile(f.wind);
    this.setPressureTile(f.pressure);
    this.setRadiationTile(f.radiation);
    this.setHumidityTile(f.humidity);
    this.lastUpdate = new Date();
    this.titleHeaderService.setTitle(this.baseTitle, "(" + this.datePipe.transform(this.lastUpdate, 'shortTime', 'CET', 'nl-NL') + ")");
  }

  subscribe() {
    const _this = this;
    this.waitForStompClientConnection(
      function () {
        _this.stompSubscription = _this.stompClient.subscribe('/topic/current', function (current) {
          let fullWeather = JSON.parse(current.body);
          _this.pushToHistory(fullWeather);
          _this.setTiles(fullWeather);
        });
      }
    );
  }

  waitForStompClientConnection(callback) {
    const _this = this;
    setTimeout(function () {
      if (!_this.stompClient.connected) {
        _this.waitForStompClientConnection(callback);
      } else {
        callback();
      }
    }, 500);
  }

  setAirQualityTile(airQuality: AirQuality) {
    let bottom = [airQuality.dailyMax];
    let top = [airQuality.airQuality, airQuality.pm1, airQuality.pm2p5];
    this.airQualityTile = {iconClass: 'wi wi-smog', iconFile: 'smoke-particles.svg', primary: airQuality.pm10,
      trend: undefined, bottom: bottom, top: top, dateFormat: 'H:mm'}
  }

  setHumidityTile(humidity: Humidity) {
    // mobile only
    let bottom = [].concat(humidity.dailyMax, humidity.dailyMin);
    this.humidityTile = {iconClass: null, primary: humidity.humidity, trend: null, bottom: bottom, top: undefined};
  }

  setRadiationTile(radiation: Radiation) {
    let bottom = [].concat(radiation.dailyMax);
    let top = [].concat(radiation.firstLight, radiation.lastLight);
    this.radiationTile = {iconClass: 'wi wi-hot', iconFile: 'sun-hot.svg', primary: radiation.solarRadiation, trend: undefined, bottom: bottom, top: top, dateFormat: 'H:mm'}
  }

  setPressureTile(pressure: Pressure) {
    let bottom = [].concat(pressure.dailyMax, pressure.dailyMin);
    let top = [].concat(pressure.trendText);
    this.pressureTile = {iconClass: 'wi wi-barometer', iconFile: 'barometer.svg', primary: pressure.pressure, trend: undefined, bottom: bottom, top: top};
  }

  setWindTile(wind: Wind) {
    let top = [].concat(wind.windGust, wind.windDirectionString, wind.beaufort);
    let bottom = [].concat(wind.dailyMax);
    let change = wind.windAverage10Minutes.value - this.getOldestHistory().wind.windAverage10Minutes.value;
    let delta = change > 3 ? 1 : (change < -3) ? -1 : 0;
    this.windTile = {iconClass: 'wi wi-strong-wind', iconFile: 'wind.svg', primary: wind.windAverage10Minutes, trend: wind.trend, bottom: bottom, top: top};
  }

  setRainTile(rain: Rain) {
    let top = [].concat(rain.lastHour, rain.lastPrecipitation);
    let bottom = [].concat(rain.monthlySum);
    this.rainTile = {iconClass: 'wi wi-raindrop', iconFile: 'rain.svg', primary: rain.dailyTotal, trend: undefined, bottom: bottom, top: top, dateFormat: 'd MMM'};
  }

  setTemperatureTile(temperature: Temperature[], humidity: Humidity, derivedTemperature: DerivedTemperature) {
    const primary = temperature.find(t => t.primary);
    const ground = temperature.find(t => t.heightAboveGroundCm <= 10);
    let top = [].concat(derivedTemperature.dewPoint, humidity.humidity);
    if (ground) {
      ground.temperature.name = 'Grastemperatuur';
      top = top.concat(ground.temperature);
    }
    let bottom = [].concat(primary.dailyMax, primary.dailyMin);
    this.temperatureTile = {iconClass: 'wi wi-thermometer', iconFile: 'thermometer.svg', primary: primary.temperature, trend: primary.trend, bottom: bottom, top: top}
  }

  ngOnDestroy(): void {
    if (this.stompSubscription) {
      this.stompSubscription.unsubscribe();
    }
    if (this.stompClient.connected) {
      this.stompClient.unsubscribe('/topic/current');
    }
  }

}
