import {Component, Input, OnInit} from '@angular/core';
import {Forecast} from "../../model/forecast.model";

@Component({
  selector: 'app-forecast-icons',
  templateUrl: './forecast-icons.component.html',
  styleUrls: ['./forecast-icons.component.scss']
})
export class ForecastIconsComponent implements OnInit {

  @Input()
  forecast: Forecast;
  @Input()
  direction: 'horizontal' | 'vertical' = 'horizontal';
  @Input()
  maxHeightPx: number = 80;

  constructor() { }

  ngOnInit(): void {
  }

  getWeatherIcon(weatherIconCode: number, cloudCoverage: number, dayTime: 'day' | 'night') {
    switch (weatherIconCode) {
      case 3:
      case 4: return 'thunderstorms-extreme-rain.svg';
      case 5:
      case 6:
      case 7: return 'overcast-sleet.svg';
      case 8:
      case 9:
      case 10:
      case 11: return 'drizzle.svg';
      case 12: return 'rain.svg';
      case 13:
      case 14: return 'snow.svg';
      case 15: return 'wind-snow.svg';
      case 16: return 'snow.svg';
      case 17: return 'hail.svg';
      case 18: return 'sleet.svg';
      case 19: return 'dust-wind.svg';
      case 20: return 'fog.svg';
      case 21: return 'haze.svg';
      case 22: return 'smoke.svg';
      case 23:
      case 24: return 'wind.svg';
      case 25: return 'wind-snow.svg';
      case 26: {
        if (cloudCoverage >= 90) {
          return 'overcast.svg';
        } else if (cloudCoverage >= 60) {
          return dayTime === 'day' ? 'overcast-day.svg' : 'overcast-night.svg';
        } else {
          return dayTime === 'day' ? 'partly-cloudy-day.svg' : 'partly-cloudy-night.svg';
        }
      }
      case 27: return 'overcast-night.svg';
      case 28: return 'overcast-day.svg';
      case 29: return 'partly-cloudy-night.svg';
      case 30: return 'partly-cloudy-day.svg';
      case 31: return 'clear-night.svg';
      case 32: return 'clear-day.svg';
      case 33: return 'partly-cloudy-night.svg';
      case 34: return 'partly-cloudy-day.svg';
      case 35: return 'overcast-hail.svg';
      case 36: return 'sun-hot.svg';
      case 37: return 'thunderstorms-day.svg';
      case 38: return 'thunderstorms.svg';
      case 39: return 'partly-cloudy-day-rain.svg';
      case 40: return 'extreme-rain.svg';
      case 41: return 'overcast-day-snow.svg';
      case 42:
      case 43: return 'extreme-show.svg';
      case 44: return 'not-available.svg';
      case 45: return 'partly-cloudy-night-rain.svg';
      case 46: return 'partly-cloudy-day-snow.svg';
      case 47: return 'thunderstorms-day-rain.svg'
      default: return 'not-available.svg';
    }

  }
}
