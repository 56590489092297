import {Component, Input, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {AstroData} from "../model/astro-data.model";
import {ViewModeService} from "../view-mode/view-mode.service";

@Component({
  selector: 'app-astro',
  templateUrl: './astro.component.html',
  styleUrls: ['./astro.component.scss']
})
export class AstroComponent implements OnInit {

  @Input()
  astroData$: Observable<AstroData[]>;
  astroData: AstroData[];

  constructor(public viewModeService: ViewModeService) { }

  get iconSize(): number {
    return this.viewModeService.isMobile ? 38 : 66
  }

  ngOnInit() {
    this.astroData$.subscribe(a => this.astroData = [].concat(a[0], a[1]));
  }

  getMoonPhaseIconFile(moonPhase: string) {
    switch (moonPhase) {
      case 'New Moon' : return 'moon-new.svg';
      case 'Full Moon' : return 'moon-full.svg';
      case 'Waxing Crescent' : return 'moon-waxing-crescent.svg';
      case 'Waning Crescent' : return 'moon-waning-crescent.svg';
      case 'Waxing Gibbous' : return 'moon-waxing-gibbous.svg';
      case 'Waning Gibbous' : return 'moon-waning-gibbous.svg';
      case 'Last Quarter': return 'moon-last-quarter.svg';
      case 'First Quarter' : return 'moon-first-quarter.svg';
    }
  }

  calculateDaylength(sunRise: string, sunSet: string): string {
    let lengthInMin = ((new Date(sunSet).getTime() - new Date(sunRise).getTime()) / 1000) / 60;

    let hours = lengthInMin / 60;
    let minutes = lengthInMin % 60;
    return '' + Math.floor(hours) + 'u' + ' ' + Math.round(minutes) + 'm';
  }

}
