import {Component, OnInit} from '@angular/core';
import {Gallery} from "ng-gallery";
import {GalleryService} from "./gallery.service";
import {map} from "rxjs/operators";
import {TitleHeaderService} from "../title-header/title-header.service";
import {Lightbox} from "ng-gallery/lightbox";
import {ActivatedRoute} from "@angular/router";
import {ExtendedImageItem} from "../model/extended-image-item.model";

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  galleryId = 'gallery';
  items: ExtendedImageItem[];

  constructor(public gallery: Gallery,
              private titleHeaderService: TitleHeaderService,
              private lightbox: Lightbox,
              private route: ActivatedRoute,
              private galleryService: GalleryService) {
  }

  ngOnInit(): void {
    this.titleHeaderService.setTitle('Galerij');

    this.galleryService.getPhotoSet()
      .pipe(map((ps) => {
        return ps.photos.map(p => {
          let src = p.photoSizes.find(sz => sz.label === 'Original');
          let thumb = p.photoSizes.find(sz => sz.label === 'Thumbnail');
          return new ExtendedImageItem({
            src: src.source,
            thumb: thumb.source,
            id: p.photoId
          });
        })
      })).subscribe((items) => {
      this.items = items.reverse();
      const galleryRef = this.gallery.ref(this.galleryId);
      galleryRef.load(this.items);

      let photoIdParam = this.route.snapshot.queryParamMap.get('phid');
      if (photoIdParam) {
        let index = this.items.findIndex(i => i.id === photoIdParam);
        if (index) {
          this.openLightBox(index);
        }
      }
    });
  }

  openLightBox(index: number) {
    this.lightbox.open(index, this.galleryId, {
      panelClass: 'fullscreen'
    });
  }

}
