<div class="mobile-card">
  <div style="width: 32%; font-size: 18px; padding-left: 5px; display: flex; flex-direction: column; justify-content: center">
    {{tile.primary.name}}
  </div>
  <div style="width: 25%; font-size: 25px; display: flex; flex-direction: column; justify-content: center; text-align: right">
    {{tile.primary.value | number: '': 'nl-NL'}}
  </div>
  <div style="display: flex; width:13%; padding-left: 5px; flex-direction: column; justify-content: center; font-size: 12px">
    {{tile.primary.unit}}
  </div>
  <div style="display: flex; flex-direction: column; width: 25%;  padding: 8px 5px 8px 0;">
    <div *ngFor="let b of tile.bottom" style="display: flex; justify-content: space-between; height: 50%;">
      <div style="font-size: 12px; display: flex; flex-direction: column; justify-content: center">{{b.shortName}}:</div>
      <div style="font-size: 13px; display: flex; flex-direction: column; justify-content: center">{{b.value | number: '': 'nl-NL'}}</div>
    </div>
  </div>
</div>
