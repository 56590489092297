export class ChartValues {
  color: string;
  backgroundColor?: string;
  showLine?: boolean = true;
  values: number[];
  pointRadius?: number = 0;
  lineWidth?: number;
  title: string
  fill?: boolean | string = false;
  hidden?: boolean = false;
  borderDash?: number[];
  order? = 0;
}
