import {ImageItem} from "ng-gallery";

export class ExtendedImageItem extends ImageItem {
  id: string;

  constructor(props: {src: string, thumb: string, id: string}) {
    super({src: props.src, thumb: props.thumb});
    this.id = props.id;
    console.log(this.id);
  }

}
