import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dateheader',
  templateUrl: './dateheader.component.html',
  styleUrls: ['./dateheader.component.scss']
})
export class DateheaderComponent implements OnInit {

  date: Date = new Date();

  constructor() { }

  ngOnInit() {
    setInterval(() => {
      this.date = new Date();
    }, 1000);
  }
}
