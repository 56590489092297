<div style="display: flex; justify-content: center">
  <div class="image" [hidden]="!loaded">
    <div class="subtitle" [style.text-align]="subtitlePos" [style.color]="subtitleColor">Laatste beeld</div>
    <img class="bordered-shadow image-frame"
         [src]="imageURI"
         (load)="imageLoaded();"
         alt="Current weathercam image"
         width="100%"/>
  </div>
  <div class="spinner w100" *ngIf="!loaded">
    <mat-spinner></mat-spinner>
  </div>
</div>
