<mat-card style="background-color: #fff; border: 1px solid #c8c8c8; min-height: 400px" [style.display]="!showChartCard ? 'none' : ''">
  <mat-card-header class="flex-end">
    <div [style.margin-right.px]="20">
      <i (click)="downloadImage()"
         style="
         cursor:pointer;
         position: absolute;
         z-index: 6"
         title="Afbeelding downloaden" class="fas fa-cloud-download-alt"></i>
    </div>
  </mat-card-header>
  <mat-card-content style="margin-bottom: 0 !important;">
    <div class="chart-container" style="position: relative;" [ngStyle]="{'min-height': !!height ? height : '600px' }">
      <canvas #canvas id="{{id}}">{{ lineChart }}</canvas>
    </div>
  </mat-card-content>
</mat-card>
