<div style="width: 100%; display: flex; justify-content: center;">
  <div style="display: flex; min-width: 100px; ">
    <img src="../../assets/weather-icons/meteocons/fill/svg-static/rain.svg"
         [height]="47">
    <div [style.font-size.px]="18"
         style=" display: flex; justify-content: center; margin: auto 0;">
      <span>{{precipChance()}}</span> <span style="font-size: 15px; margin: auto 0 1px 1px;">%</span>
    </div>
  </div>
</div>
