import {Component, Input, OnInit} from '@angular/core';
import {ChartModel} from "../../model/chart-model.model";
import {DailyAverages} from "../../model/daily-averages.model";
import {DailyExtremes} from "../../model/daily-extremes.model";
import {ChartValues} from "../../model/chart-values.model";

@Component({
  selector: 'app-statistics-chart',
  templateUrl: './statistics-chart.component.html',
  styleUrls: ['./statistics-chart.component.scss']
})
export class StatisticsChartComponent implements OnInit {

  chartData: ChartModel;
  shownId: string = 'temp';
  _dailyAverages: DailyAverages[];
  _dailyExtremes: DailyExtremes[];
  @Input()
  month: number;
  @Input()
  year: number;
  _loading: boolean;

  constructor() { }

  @Input()
  set loading(loading: boolean) {
    this._loading = loading;
    if (!this._loading && this._dailyAverages && this._dailyExtremes) {
      // loading is set to false when both dailyAverages and dailyExtremes have been (re)loaded
      this.setChartData();
    }
  }

  @Input()
  set dailyAverages(dailyAverages: DailyAverages[]) {
    this._dailyAverages = dailyAverages;

  }

  @Input()
  set dailyExtremes(dailyExtremes: DailyExtremes[]) {
    this._dailyExtremes = dailyExtremes;
  }

  ngOnInit() {
  }

  setChartData() {
    let cm = new ChartModel();
    cm.title = 'Gemiddelde temperatuur'
    cm.labels = this._dailyExtremes.map((av) => av.date).reverse();
    cm.data = [].concat(...[
      this.extractAverages('averageTemperatureCelsius'),
      this.extractHighs('maxTemperature'),
      this.extractLows('minTemperature')]);
    cm.unit = '°C';
    cm.subtitle = 'meteodrenthe.nl';
    cm.timeUnit = 'day';
    cm.dateFormat = 'DD-MM';
    this.chartData = cm;
  }

  extractLows(dataKey: string): ChartValues {
    let data = this._dailyExtremes.map((de) => de[dataKey].value).reverse();
    let cv = new ChartValues();
    cv.values = data;
    cv.showLine = true;
    cv.pointRadius = 1;
    cv.color = 'blue';
    cv.title = 'Min. temperatuur';
    return cv;
  }

  extractHighs(dataKey: string): ChartValues {
    let data = this._dailyExtremes.map((de) => de[dataKey].value).reverse();
    let cv = new ChartValues();
    cv.values = data;
    cv.showLine = true;
    cv.pointRadius = 1;
    cv.color = 'red';
    cv.title = 'Max. temperatuur';
    return cv;
  }

  extractAverages(dataKey: string): ChartValues {
    let data = this._dailyAverages.map((av) => av[dataKey]).reverse();
    let cv = new ChartValues();
    cv.values = data;
    cv.showLine = true;
    cv.pointRadius = 1;
    cv.color = '#a89999';
    cv.title = 'Gem. temperatuur';
    return cv;
  }

}
