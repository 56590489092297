import {Component, Input, OnInit} from '@angular/core';
import {SimpleExtremes} from "../../model/simple-extremes.model";
import {SimpleAverages} from "../../model/monthly-averages.model";
import {RainSums} from "../../model/rain-sums.model";

@Component({
  selector: 'app-period-summary',
  templateUrl: './period-summary.component.html',
  styleUrls: ['./period-summary.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class PeriodSummaryComponent implements OnInit {

  @Input()
  extremes: SimpleExtremes;
  @Input()
  averages: SimpleAverages;
  @Input()
  period: string;
  screenWidth: number;
  @Input()
  rainSum: number;

  constructor() { }

  ngOnInit() {
    this.screenWidth = window.innerWidth;
  }

  onResize(event) {
    this.screenWidth = event.target.innerWidth; // window width
  }

}
