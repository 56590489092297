<div class="ym-button-container">
  <div>
    <i class="fas fa-chevron-right"></i>
    <button mat-button
            title="Maand"
            [matMenuTriggerFor]="monthMenu">{{allMonths[month - 1]}}</button>
    <mat-menu #monthMenu="matMenu">
      <button class="option"
              [disabled]="!monthAvailable(m)"
              *ngFor="let m of allMonths; let i = index;" mat-menu-item (click)="setMonth(m)">{{m}}</button>
    </mat-menu>
  </div>
  <div>
    <button mat-button
            title="Maand"
            [matMenuTriggerFor]="yearMenu">{{_year}}</button>
    <i class="fas fa-chevron-left"></i>
    <mat-menu #yearMenu="matMenu">
      <button class="option" *ngFor="let y of years" mat-menu-item (click)="setYear(y)">{{y}}</button>
    </mat-menu>
  </div>
</div>
